<template>
  <v-dialog v-model="dialog" max-width="1024" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Coincidencias</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="cebra elevation-0 mt-2"
          item-key="id"
          sort-by="id"
          :headers="headers"
          :items="coincidencias"
          :loading="load"
          sort-desc
          dense
          :search="search"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <BtnConfirmar
              :icon_button="true"
              :small="true"
              icono="far fa-check-circle"
              @action="asignar(item)"
              color="success"
              title="Asignar"
              texto="¿Desea asignar este registro?"
            />
          </template>
          <template v-slot:[`item.DETDEBE`]="{ item }">
            {{ formatMonto(item.DETDEBE) }}
          </template>
          <template v-slot:[`item.Nombre`]="{ item }">
            <span v-if="item.Nombre">
              {{ item.Nombre }}
            </span>
            <span v-else>Sin nombre</span>
          </template>
          <template v-slot:[`item.DETREF`]="{ item }">
            <span v-if="item.DETREF.length > 0">
              {{ item.DETREF }}
            </span>
            <span v-else> Sin referencia </span>
          </template>

          <template v-slot:[`item.DETFECHA`]="{ item }">
            {{ formatFecha(item.DETFECHA) }}
          </template>
        </v-data-table>

        <div class="d-flex mt-3">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { format_money } from "../../util/utils";
import BtnConfirmar from "../util/BtnConfirmar.vue";

export default {
  data() {
    return {
      load: false,
      search: "",

      headers: [
        {
          text: "Nombre",
          value: "Nombre",
        },
        {
          text: "Comentario",
          value: "DETComenta",
          width: "300px",
        },
        { text: "Monto", value: "DETDEBE" },
        { text: "Referencia", value: "DETREF" },
        { text: "Fecha movimiento", value: "DETFECHA" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          align: "center",
        },
      ],
    };
  },

  methods: {
    formatMonto(monto) {
      return format_money(monto);
    },

    formatFecha(fecha) {
      return moment(fecha).format("DD/MM/YYYY");
    },

    async asignar(item) {
      if(this.tipo == 0){
        item.tipo = 'RETENCION'
      } else {
        item.tipo = 'PERCEPCION'
      }

      this.$store.state.loading = true;
      await this.$store
        .dispatch("retencionesPercepciones/asignarCoincidencia", {
          DETMOVID: item.DETMOVID,
          id: this.itemSeleccionado.Id,
          tipo: item.tipo
        })
        .then((res) => {
          if (res.exito) {
            this.$store.dispatch("show_snackbar", {
              text: res.message,
              color: "success",
            });

            this.$emit("asignado", true);
          } else {
            this.$store.dispatch("show_snackbar", {
              text: res.message,
              color: "error",
            });
          }
        });
      this.$store.state.loading = false;
    },
  },

  watch: {},

  async created() {},

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  props: {
    value: Boolean,
    coincidencias: Array,
    itemSeleccionado: Object,
    tipo: Number
  },

  components: {
    BtnConfirmar,
  },
};
</script>
