<template>
    <v-dialog v-model="dialog" max-width="550" scrollable>
        <v-card>
            <v-card-title primary-title>
                <h4>
                    Anular recibo N° {{ data.numero }}
                </h4>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
                    <v-col cols="12" sm="12" md="12" class="py-3">
                        <v-textarea v-model.trim="comentario" label="Comentario" rows="2" hide-details auto-grow
                            outlined dense></v-textarea>
                    </v-col>
                </v-row>

                <div class="d-flex mt-10">
                    <v-btn @click="dialog = false" style="margin-left: auto" color="primary">Volver</v-btn>
                    <div class="ml-2">
                        <BtnConfirmar color="error" icono="fas fa-ban" nombre="Anular" @action="anular()" />
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../../util/BtnConfirmar.vue';

export default {
    data() {
        return {
            load: false,
            comentario: '',
            data: {
                numero: ''
            }
        }
    },

    methods: {
        async anular() {
            if (this.comentario.length > 0) {
                this.$store.state.loading = true
                await this.$store
                    .dispatch('recibosCombustible/anularRecibo', {
                        recibo_id: this.recibo.Id,
                        comentario: this.comentario
                    })
                    .then(async res => {
                        if (res.exito) {
                            this.dialog = false
                            this.$swal.fire({
                                icon: 'success',
                                title: res.message,
                            })

                            this.$emit('actualizar', true)
                        } else {
                            this.$store.dispatch('show_snackbar', {
                                text: res.message,
                                color: 'error'
                            })
                        }
                    })
            } else {
                this.$store.dispatch('show_snackbar', {
                    text: 'El campo comentario es obligatorio.',
                    color: 'warning'
                })
            }
            this.$store.state.loading = false
        }
    },

    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },

    props: {
        value: Boolean,
        recibo: {
            type: Object
        },
    },

    watch: {
        dialog(val) {
            if (val) {
                this.comentario = ''

                let numeroFormateado = 0
                if (this.recibo.Voucher) {
                    numeroFormateado = `0001-${this.recibo.Id.toString().padStart(8, '0')}`
                } else {
                    numeroFormateado = this.recibo.Id.toString().padStart(8, '0');
                }
                this.data.numero = numeroFormateado

            }
        },

    },

    components: {
        BtnConfirmar
    }
}
</script>