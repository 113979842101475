<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Descargar números del talonario</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Tipo
            <v-autocomplete v-model="data.tipo" item-text="Nombre" item-value="Id" hide-details outlined dense
              :items="tipos_talonarios"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete v-model="data.familia" item-text="nombre" item-value="id" hide-details outlined dense
              clearable :items="familias"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Sucursal
            <v-autocomplete v-model="data.sucursal" item-text="provincia" item-value="id" hide-details outlined dense
              clearable :items="sucursales.filter(suc => suc.familia_id === data.familia)"></v-autocomplete>
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn @click="dialog = false" style="margin-left: auto" color="primary">Volver</v-btn>
          <div class="ml-2">
            <BtnConfirmar icono="fas fa-file-download" nombre="Descargar" @action="descargar()" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import { utils, write } from 'xlsx'
import FileSaver from 'file-saver'

export default {
  data() {
    return {
      load: false,
      data: {
        tipo: null,
        sucursal: null,
        familia: null
      },

      numerosDisponibles: []
    }
  },

  methods: {
    async descargar() {
      // RECUPERAR LOS DISPONIBLES DE CADA TALONARIO CON ESTADO 2
      // COLUMNAS TALONARIO, NUMERO,
      let numerosTalonario = []
      if (this.data.tipo) {
        await this.$store
          .dispatch('talonarios/getNumerosMasivo', {
            ...this.data
          })
          .then(res => {
            if (res.exito) {
              numerosTalonario = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })


        const cabecera = [['Número', 'Estado', 'Sucursal']]

        const ws = utils.aoa_to_sheet(cabecera)
        const array = numerosTalonario

        utils.sheet_add_json(ws, array, {
          header: ['numero', 'estado', 'sucursal_nombre'],
          skipHeader: true,
          origin: -1
        })

        const columnas = Object.keys(array[0])
        columnas.forEach(columna => {
          const maxLength = array.reduce(
            (max, row) => Math.max(max, String(row[columna]).length),
            0
          )
          ws['!cols'] = ws['!cols'] || []
          ws['!cols'].push({ wch: maxLength + 2 })
        })

        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, 'Reporte')
        const wbout = write(wb, { bookType: 'xlsx', type: 'array' })
        let name =
          `Talonarios_disponibles` +
          '.xlsx'
        FileSaver.saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          name
        )
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar un tipo para poder descargar.',
          color: 'warning'
        })
      }
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.data = {
          familia: null,
          sucursal: null,
          tipo: null
        }
      }
    },
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias']),
    ...mapState('genericos', ['sucursales'])
  },

  props: {
    value: Boolean,
    tipos_talonarios: Array
  },

  created() {
    if (this.familias.length == 1) this.data.familia = this.familias[0].id
  },

  components: {
    BtnConfirmar
  }
}
</script>
