<template>
    <v-dialog v-model="dialog" max-width="700" scrollable>
        <v-card>
            <v-card-title primary-title>
                <h4>Auditar recibo N° {{ info.numero }}</h4>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog = false">
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
                    <v-col cols="12" sm="4" md="4" class="py-1">
                        Fecha de pago
                        <FechaPicker v-model="data.fecha" :hideDetails="true" />
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1">
                        Número Factura
                        <v-text-field-simplemask v-model.trim="data.factura" v-bind:properties="{
                            'hide-details': true,
                            outlined: true,
                            dense: true,
                            placeholder: '####-########',
                            hideDetails: true
                        }" v-bind:options="{
                            inputMask: '####-########',
                            outputMask: '####-########',
                            applyAfter: false,
                            alphanumeric: true,
                            empty: ''
                        }" />
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-1">
                        Importe
                        <v-text-field-money v-model="data.importe" type="number" v-bind:properties="{
                            prefix: '$',
                            outlined: true,
                            dense: true,
                            hideDetails: true
                        }"></v-text-field-money>
                    </v-col>

                    <v-col cols="12" sm="12" md="12" class="py-2 pb-5">
                        Comentario
                        <v-textarea v-model.trim="data.comentario" rows="2" hide-details auto-grow outlined
                            dense></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="2" md="12" class="py-0 mt-2 mb-3">
                        <div style="width: 100; display: flex; justify-content: end">
                            <BtnConfirmar @action="auditar" icono="fas fa-check" nombre="Auditar" color="success" />
                        </div>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import TextFieldMoney from '../../../components/util/TextFieldMoney.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'
import FechaPicker from '../../util/FechaPicker.vue';

export default {
    data() {
        return {
            load: false,
            data: {
                fecha: '',
                importe: null,
                factura: '',
                comentario: ''
            },
            info: {
                numero: 0
            }
        }
    },

    methods: {
        async auditar() {
            if (this.data.fecha.length > 0 && this.data.factura.length > 0 && this.data.importe) {
                this.$store.state.loading = true
                await this.$store
                    .dispatch('recibosCombustible/auditarRecibo', {
                        recibo_id: this.recibo.Id,
                        importe: this.data.importe,
                        fecha: this.data.fecha,
                        factura: this.data.factura,
                        comentario: this.data.comentario
                    })
                    .then(res => {
                        if (res.exito) {
                            this.$emit('actualizar', true)
                            this.dialog = false
                            this.$swal.fire({
                                icon: 'success',
                                title: res.message
                            })
                        } else {
                            this.$swal.fire({
                                icon: 'error',
                                title: res.message
                            })
                        }
                    })
                this.$store.state.loading = false
            } else {
                this.$store.dispatch('show_snackbar', {
                    text: 'Los campos "Fecha de pago", "Número Factura" e "Importe" son obligatorios.',
                    color: 'warning'
                })
            }
        },
    },

    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },

    props: {
        value: Boolean,
        recibo: Object
    },

    watch: {
        async dialog(val) {
            if (val) {
                let numeroFormateado = 0
                if (this.recibo.Voucher) {
                    numeroFormateado = `0001-${this.recibo.Id.toString().padStart(8, '0')}`
                } else {
                    numeroFormateado = this.recibo.Id.toString().padStart(8, '0');
                }
                this.info.numero = numeroFormateado
            } else {
                this.data.factura = ''
                this.data.fecha = ''
                this.data.importe = null
            }
        }
    },

    components: {
        TextFieldMoney,
        BtnConfirmar,
        FechaPicker
    }
}
</script>