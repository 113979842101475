<template>
  <v-dialog v-model="dialog" max-width="550" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>
          Anular talonario {{ data.tipo_nombre }} - {{ data.Nro_desde }} /
          {{ data.Nro_hasta }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="12" md="12" class="py-3">
            <v-textarea
              v-model.trim="comentario"
              label="Comentario"
              rows="2"
              hide-details
              auto-grow
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
          <div class="ml-2">
            <BtnConfirmar
              :color="anular ? 'error' : 'success'"
              :icono="anular ? 'fas fa-ban' : 'fas fa-check-circle'"
              :nombre="anular ? 'Anular' : 'Habilitar'"
              @action="modificarEstado()"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar.vue'

export default {
  data() {
    return {
      load: false,
      comentario: '',
      data: {
        tipo_nombre: '',
        Nro_desde: 0,
        Nro_hasta: 0
      }
    }
  },

  methods: {
    async modificarEstado() {
      if (this.anular) {
        if (this.comentario.length > 0) {
          this.$store.state.loading = true
          await this.$store
            .dispatch('talonarios/inhabilitarTalonario', {
              talonario_id: this.talonario.Id,
              comentario: this.comentario
            })
            .then(async res => {
              if (res.exito) {
                this.$emit('buscar', true)
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
                this.dialog = false
              } else {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'error'
                })
              }
            })
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'El campo comentario es obligatorio.',
            color: 'warning'
          })
        }
        this.$store.state.loading = false
      } else {
        this.$store.state.loading = true
        await this.$store
          .dispatch('talonarios/habilitarTalonario', {
            talonario_id: this.talonario.Id,
            comentario: this.comentario
          })
          .then(async res => {
            if (res.exito) {
              this.$emit('buscar', true)
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.dialog = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias']),
    ...mapState('genericos', ['sucursales'])
  },

  props: {
    value: Boolean,
    talonario: {
      type: Object
    },
    anular: {
        type: Boolean,
        default: false
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.comentario = ''
        this.data.tipo_nombre = this.talonario.tipo_nombre
        this.data.Nro_desde = this.talonario.Nro_desde
        this.data.Nro_hasta = this.talonario.Nro_hasta
      }
    },

  },

  created() {
    //   if (this.familias.length == 1) this.data.familia = this.familias[0].id
  },

  components: {
    BtnConfirmar
  }
}
</script>
