<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Asignación de formulario</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Tipo
            <v-autocomplete
              v-model="data.tipo"
              item-text="Nombre"
              hide-details
              outlined
              dense
              return-object
              :items="tipos_talonarios"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="3" xl="3" class="py-1">
            Número
            <v-text-field
              v-model.number="data.numero"
              hide-details
              outlined
              dense
              type="number"
              @change="validarNumero"
            ></v-text-field>
          </v-col>
          <v-col v-if="data.tipo.Usuario" cols="12" sm="4" md="4" class="py-1">
            Usuario
            <v-autocomplete
              :disabled="!numeroValido"
              v-model="data.usuario"
              item-text="Nombre"
              item-value="Usuario_id"
              hide-details
              outlined
              dense
              :items="usuarios"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col v-if="data.tipo.Cliente" cols="6" sm="5" xl="4" class="py-1">
            Cliente
            <v-text-field
              :disabled="!numeroValido"
              v-model.trim="data.cliente"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col v-if="data.tipo.Patente" cols="6" sm="3" xl="3" class="py-1">
            Patente
            <v-text-field
              :disabled="!numeroValido"
              v-model.trim="data.patente"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
          <div class="ml-2">
            <BtnConfirmar
              :disabled="!numeroValido"
              @action="asignarFormulario()"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FechaPicker from '../util/FechaPicker.vue'

export default {
  data() {
    return {
      load: false,
      permiso: true,

      data: {
        tipo: {},
        sucursal: null,
        numero: null,
        usuario: null,
        patente: '',
        cliente: '',
        empresa: null,
        talonario_id: null,
        estado_incial: null
      },

      usuarios: [],

      numeroValido: false
    }
  },

  methods: {
    async asignarFormulario() {
      if (
        Object.keys(this.data.tipo).length > 0 &&
        this.data.numero &&
        this.permiso
      ) {
        if (this.data.tipo.Estado_inicial === 1) {
          if (!this.data.usuario) {
            this.$store.dispatch('show_snackbar', {
              text: 'El campo usuario es obligatorio.',
              color: 'warning'
            })
            return
          }
        }

        this.$store.state.loading = true
        await this.$store
          .dispatch('asignacionFormularios/asignarFormulario', {
            talonario_id: this.data.talonario_id,
            usuario: this.data.usuario || null,
            numero: this.data.numero,
            cliente: this.data.cliente,
            patente: this.data.patente || null,
            estado_inicial: this.data.tipo.Estado_inicial
          })
          .then(res => {
            if (res.exito) {
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.dialog = false
              this.numeroValido = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes completar los campos Empresa, Sucursal, Tipo, Nº Desde, Nº Hasta',
          color: 'warning'
        })
      }
    },

    async getSucursal(tipo, numero) {
      this.$store.state.loading = true
      await this.$store
        .dispatch('asignacionFormularios/getSucursal', {
          tipo,
          numero
        })
        .then(res => {
          if (res.exito) {
            this.data.sucursal = res.data.Sucursal_id
            this.data.empresa = res.data.Empresa_id
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'warning'
            })
          }
        })
      this.$store.state.loading = false
    },

    async getUsuarios(empresa) {
      if (this.data.tipo.Usuario) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('asignacionFormularios/getUsuarios', {
            empresa
          })
          .then(res => {
            if (res.exito) {
              this.usuarios = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })

        this.$store.state.loading = false
      }
    },

    async validarNumero() {
      if (Object.keys(this.data.tipo).length > 0) {
        const numero = Number(this.data.numero)

        if (!!numero) {
          this.$store.state.loading = true

          await this.$store
            .dispatch('asignacionFormularios/validarNumero', {
              tipo: this.data.tipo.Id,
              numero
            })
            .then(res => {
              if (!res.exito) {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'warning'
                })
              } else {
                this.data.talonario_id = res.talonario_id
              }
              this.numeroValido = res.exito
            })
          await this.getSucursal(this.data.tipo.Id, this.data.numero)
          if (this.data.empresa) {
            await this.getUsuarios(this.data.empresa)
          }
          this.$store.state.loading = false
        }
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar un tipo.',
          color: 'warning'
        })
      }
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.data = {
          tipo: {},
          sucursal: null,
          numero: null,
          usuario: null,
          patente: '',
          prefirmado: false,
          cliente: '',
          empresa: null,
          talonario_id: null,
          estado_incial: null
        }

        this.numeroValido = false
      }
    },

    'data.tipo'(val) {
      this.numeroValido = false
      this.data.numero = null
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias']),
    ...mapState('genericos', ['sucursales']),
    ...mapGetters(['una_familia'])
  },

  props: {
    value: Boolean,
    tipos_talonarios: Array
  },

  created() {
    if (this.una_familia) this.data.familia = this.familias[0].id
  },

  components: {
    BtnConfirmar,
    FechaPicker
  }
}
</script>
