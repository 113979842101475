<template>
  <v-dialog v-model="dialog" max-width="1024" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Archivos</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-col cols="12" sm="3" md="3" class="">
          Fecha desde
          <FechaPicker v-model="filtro.fecha_desde" hide-details clearable />
        </v-col>
        <v-data-table
          class="cebra elevation-0 mt-2"
          item-key="id"
          sort-by="id"
          :headers="headers"
          :items="localEncabezados"
          :loading="load"
          sort-desc
          dense
          :search="search"
        >
          <template v-slot:[`item.acciones`]="{ item }">
            <BtnConfirmar
              :icon_button="true"
              :small="true"
              icono="fas fa-trash-alt"
              @action="eliminar(item)"
              color="error"
              title="Eliminar"
              texto="¿Desea eliminar este registro?"
            />
          </template>
          <template v-slot:[`item.Fecha_carga`]="{ item }">
            {{ formatFecha(item.Fecha_carga) }}
          </template>
        </v-data-table>

        <div class="d-flex mt-3">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
          >Volver</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import { mapState } from 'vuex'
import FechaPicker from '../util/FechaPicker.vue';

export default {
  data() {
    return {
      load: false,
      search: '',
      localEncabezados: [],

      filtro: {
        fecha_desde: ''
      },

      headers: [
        {
          text: 'Empresa',
          value: 'nombre_empresa'
        },
        {
          text: 'Nombre',
          value: 'Nombre_archivo'
        },
        { text: 'Fecha carga', value: 'Fecha_carga' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center'
        }
      ]
    }
  },

  methods: {
    async eliminar(item) {
      this.$store.state.loading = true
      await this.$store
        .dispatch('retencionesPercepciones/eliminarEncabezado', {
          id: item.Id,
          tipo: this.tipo === 0 ? 'RETENCION' : 'PERCEPCION'
        })
        .then(res => {
          if (res.exito) {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'success'
            })

            this.$emit('encabezadoEliminado', true)
            this.dialog = false
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false
    },

    formatFecha(fecha) {
      return moment(fecha).format('DD/MM/YYYY')
    }
  },

  watch: {
    async 'filtro.fecha_desde'(val) {
      if (val.length > 0 && val) {
        await this.$store.dispatch('retencionesPercepciones/getEncabezados', {
          tipo: this.tipo,
          fecha: val
        }).then(res => {
          if (res.exito) {
            this.localEncabezados = res.data

            this.localEncabezados.forEach(encabezado => {
              encabezado.nombre_empresa = this.familias.find(empresa => empresa.id == encabezado.Empresa_id)?.nombre
            })
          }
        })
      }
    },

    dialog(val){
      if(val){
        this.localEncabezados = this.encabezados
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    ...mapState(['familias'])
  },

  props: {
    value: Boolean,
    encabezados: Array,
    tipo: Number
  },

  created() {
    this.localEncabezados = [...this.encabezados];
  },

  components: {
    BtnConfirmar,
    FechaPicker
  }
}
</script>
