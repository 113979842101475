<template>
  <v-dialog v-model="dialog" max-width="700" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Editar voucher</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete v-model="data.familia" item-text="nombre" item-value="id" hide-details outlined dense
              clearable :items="familias" @change="setEmpresas" disabled></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Marca
            <v-autocomplete disabled v-model="data.empresa" item-text="nombre" item-value="id" hide-details outlined
              dense clearable :items="empresasFiltradas.filter(emp => emp.familia === data.familia)
                " @change="setSucursal"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Sucursal
            <v-autocomplete disabled v-model="data.sucursal" item-text="provincia" item-value="id" hide-details outlined
              dense clearable :items="sucursalesFiltradas.filter(
                suc => suc.familia_id === data.familia
              )
                "></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" xl="4" class="py-1">
            Dirección
            <v-text-field v-model.trim="data.direccion" hide-details outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Imagen estación
            <v-autocomplete v-model="data.imagen" item-text="nombre" hide-details outlined dense clearable return-object
              :items="imagenes">
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>{{ item.nombre }}</v-list-item-content>
                  <v-list-item-icon small>
                    <v-icon small color="info">fas fa-image</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Monto
            <v-text-field-money v-model="data.monto" type="number" v-bind:properties="{
              prefix: '$',
              outlined: true,
              dense: true
            }"></v-text-field-money>
          </v-col>
          <v-col cols="12" sm="2" md="12" class="py-0 mb-3">
            <div style="width: 100; display: flex; justify-content: end">
              <BtnConfirmar @action="editar" icono="fas fa-pen" nombre="Editar" color="warning" />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import TextFieldMoney from '../../../components/util/TextFieldMoney.vue'
import BtnConfirmar from '../../util/BtnConfirmar.vue'

export default {
  data() {
    return {
      load: false,
      sucursalesFiltradas: [],
      empresasFiltradas: [],

      voucherSeleccionad: {},

      imagenes: [],

      data: {
        familia: null,
        empresa: null,
        sucursal: null,
        monto: null,
        imagen: 'prueba',
        direccion: ''
      }
    }
  },

  methods: {
    async editar() {
      this.$store.state.loading = true
      await this.$store
        .dispatch('vouchersCombustible/editarVoucher', {
          id: this.voucher.Id,
          monto: this.data.monto,
          imagen: this.data.imagen.url,
          direccion: this.data.direccion
        })
        .then(res => {
          if (res.exito) {
            this.$emit('actualizar', true)
            this.dialog = false
            this.$swal.fire({
              icon: 'success',
              title: res.message
            })
          } else {
            this.$swal.fire({
              icon: 'error',
              title: res.message
            })
          }
        })
      this.$store.state.loading = false
    },

    setEmpresas() {
      const empresa = this.empresas.filter(
        emp => emp.familia === this.data.familia
      )
      empresa.length === 1 ? (this.data.empresa = empresa[0].id) : null
    },

    setSucursal() {
      const sucursal = this.sucursales.filter(
        suc => suc.familia_id === this.data.familia
      )
      sucursal.length === 1 ? (this.data.sucursal = sucursal[0].id) : null
    },

    abrirModalNuevoVoucher() {
      // ANTES DE ABRIR ESTE MODAL NECESITO SABER SI NO EXISTE UNO PARA ESA EMPRESA
      this.modalNuvoVoucher = true
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias', 'empresas']),
    ...mapState('genericos', ['sucursales'])
  },

  props: {
    value: Boolean,
    voucher: Object
  },

  watch: {
    async dialog(val) {
      if (val) {
        this.data.monto = this.voucher.Importe
        this.$store.state.loading = true
        await this.$store
          .dispatch('vouchersCombustible/getImagenesEstaciones')
          .then(res => {
            if (res.exito) {
              this.imagenes = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false

        this.data.familia = this.voucher.Familia_id
        this.data.empresa = this.voucher.Empresa_id
        this.data.sucursal = this.voucher.Sucursal_id
        this.data.direccion = this.voucher.Direccion
        // this.data.imagen = this.voucher.Imagen

        this.data.imagen = this.imagenes.find(
          imagen => imagen.url === this.voucher.Imagen
        )
      } else {
        this.data.familia = null
        this.data.empresa = null
        this.data.sucursal = null
        this.data.direccion = null
        this.data.imagen = ''
      }
    }
  },

  created() {
    if (this.familias.length == 1) this.data.familia = this.familias[0].id
    this.sucursalesFiltradas = this.sucursales
    this.empresasFiltradas = this.empresas
  },

  components: {
    TextFieldMoney,
    BtnConfirmar
  }
}
</script>
