var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"700","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',[_vm._v("Nuevo importe voucher")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Empresa "),_c('v-autocomplete',{attrs:{"item-text":"nombre","item-value":"id","hide-details":"","outlined":"","dense":"","clearable":"","items":_vm.familias},on:{"change":_vm.setEmpresas},model:{value:(_vm.data.familia),callback:function ($$v) {_vm.$set(_vm.data, "familia", $$v)},expression:"data.familia"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"4"}},[_vm._v(" Marca "),_c('v-autocomplete',{attrs:{"item-text":"nombre","item-value":"id","hide-details":"","outlined":"","dense":"","clearable":"","items":_vm.empresasFiltradas.filter(emp => emp.familia === _vm.data.familia)},on:{"change":_vm.setSucursal},model:{value:(_vm.data.empresa),callback:function ($$v) {_vm.$set(_vm.data, "empresa", $$v)},expression:"data.empresa"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"4"}},[_vm._v(" Sucursal "),_c('v-autocomplete',{attrs:{"item-text":"provincia","item-value":"id","hide-details":"","outlined":"","dense":"","clearable":"","items":_vm.sucursalesFiltradas.filter(
              suc => suc.familia_id === _vm.data.familia
            )},model:{value:(_vm.data.sucursal),callback:function ($$v) {_vm.$set(_vm.data, "sucursal", $$v)},expression:"data.sucursal"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","xl":"4"}},[_vm._v(" Dirección Estación "),_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":""},model:{value:(_vm.data.direccion),callback:function ($$v) {_vm.$set(_vm.data, "direccion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.direccion"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"11","sm":"3","md":"3"}},[_vm._v(" Imagen estación "),_c('v-autocomplete',{attrs:{"item-text":"nombre","hide-details":"","outlined":"","dense":"","clearable":"","return-object":"","items":_vm.imagenes},scopedSlots:_vm._u([{key:"item",fn:function({ item, on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_vm._v(_vm._s(item.nombre))]),_c('v-list-item-icon',{attrs:{"small":""}},[_c('v-icon',{attrs:{"small":"","color":"info"}},[_vm._v("fas fa-image")])],1)],1)]}}]),model:{value:(_vm.data.imagen),callback:function ($$v) {_vm.$set(_vm.data, "imagen", $$v)},expression:"data.imagen"}})],1),(_vm.data.imagen)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"1","sm":"1","md":"1"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","height":"100%"}},[_c('v-btn',{attrs:{"small":"","color":"info","icon":"","title":"Ver imagen"},on:{"click":_vm.toogleImagen}},[_c('v-icon',[_vm._v("fas fa-eye")])],1)],1)]):_vm._e(),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Monto "),_c('v-text-field-money',{attrs:{"type":"number","properties":{
            prefix: '$',
            outlined: true,
            dense: true
          }},model:{value:(_vm.data.monto),callback:function ($$v) {_vm.$set(_vm.data, "monto", $$v)},expression:"data.monto"}})],1),(_vm.mostrarImagen)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('img',{attrs:{"src":_vm.urlImagenSeleccionada,"alt":_vm.nombreImagenSeleccionada}})]):_vm._e(),_c('v-col',{staticClass:"py-0 mb-3",attrs:{"cols":"12","sm":"2","md":"12"}},[_c('div',{staticStyle:{"width":"100","display":"flex","justify-content":"end"}},[_c('BtnConfirmar',{on:{"action":_vm.guardar}})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }