<template>
  <v-dialog v-model="dialog" max-width="800" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Subir archivo</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-5 align-center">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete
              v-model="empresa"
              item-text="nombre"
              return-object
              hide-details
              outlined
              dense
              clearable
              :items="familias"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-1">
            <FileDropzone
              :files.sync="archivo"
              :tipos="tipos_validos"
              :multiple="false"
              :max_size="25"
            />
          </v-col>

          <v-col cols="12" class="py-1">
            <div style="display: flex; justify-content: end">
              <BtnConfirmar
                @action="subir"
                nombre="subir"
                icono="fas fa-file-upload"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FileDropzone from '../util/FileDropzone.vue'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import { read, utils } from 'xlsx'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      archivo: [],
      nombreArchivo: '',
      minimaFecha: '',
      idEncabezado: null,
      cuit_cuil: '',
      tipos_validos: ['.xls', '.xlsx', '.ods'],
      select: {
        empresa: {},
        tipo: {},
        desde: null
      },
      empresas: [],
      datos: [],
      empresa: {},

      coincidencias: [],
      tipo: ''
    }
  },

  methods: {
    async subir() {
      if (Object.keys(this.empresa).length > 0) {
        this.archivo.length == 0
          ? this.$store.dispatch('show_snackbar', {
              text: 'Sin archivo seleccionado',
              color: 'warning'
            })
          : null

        this.datos.forEach(item => {
          item.empresa = this.empresa.id
        })

        // SUBIR ENCABEZADOS
        this.$store.state.loading = true

        await this.$store
          .dispatch('retencionesPercepciones/subirEncabezados', {
            empresa: this.empresa.id,
            tipo: this.datos[0].descripcion,
            nombre: this.nombreArchivo
          })
          .then(async res => {
            if (res.exito) {
              this.idEncabezado = res.idInsertado
              this.datos.forEach(dato => {
                dato.idEncabezado = this.idEncabezado
              })
              if (this.datos[0].descripcion == 'RETENCION') {
                this.tipo = 'RETENCION'
                await this.$store
                  .dispatch('retencionesPercepciones/subirRetenciones', {
                    retenciones: this.datos,
                    empresa: this.empresa.id
                  })
                  .then(async res => {
                    if (res.exito) {
                      // insertar los sobrantes.
                      if (res.counterRowsAffected > 0) {
                        await this.$store
                          .dispatch('retencionesPercepciones/subirSobrantes', {
                            minFecha: this.minimaFecha,
                            empresa: this.empresa.id,
                            tipo: 'RETENCION',
                            id_archivo: this.idEncabezado
                          })
                          .then(res => {
                            if (!res.exito) {
                              this.$store.dispatch('show_snackbar', {
                                text: res.message,
                                color: 'error'
                              })
                            }
                            this.$store.state.loading = false
                          })
                      }
                      this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'success'
                      })
                      this.archivo = []

                      let minFecha = moment(
                        this.datos[0].fechaRangoSeisMeses,
                        'DD/MM/YYYY'
                      )
                      this.datos.forEach(factura => {
                        const fecha = moment(
                          factura.fechaRangoSeisMeses,
                          'DD/MM/YYYY'
                        )
                        if (fecha.isBefore(minFecha)) {
                          minFecha = fecha
                        }
                      })

                      minFecha = minFecha.format('DD/MM/YYYY')

                      this.$emit('verItems', {
                        minFecha,
                        empresa: this.empresa,
                        tipo: this.tipo
                      })
                      this.dialog = false
                    } else {
                      this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                      })
                    }
                  })
              }

              if (this.datos[0].descripcion == 'PERCEPCION') {
                this.tipo = 'PERCEPCION'

                await this.$store
                  .dispatch('retencionesPercepciones/subirPercepciones', {
                    percepciones: this.datos,
                    empresa: this.empresa.id
                  })
                  .then(async res => {
                    if (res.exito) {
                      // insertar los sobrantes.
                      if (res.counterRowsAffected > 0) {
                        await this.$store
                          .dispatch('retencionesPercepciones/subirSobrantes', {
                            minFecha: this.minimaFecha,
                            empresa: this.empresa.id,
                            tipo: 'PERCEPCION',
                            id_archivo: this.idEncabezado
                          })
                          .then(res => {
                            if (!res.exito) {
                              this.$store.dispatch('show_snackbar', {
                                text: res.message,
                                color: 'error'
                              })
                            }
                            this.$store.state.loading = false
                          })
                      }

                      let minFecha = moment(
                        this.datos[0].fechaRangoSeisMeses,
                        'DD/MM/YYYY'
                      )
                      this.datos.forEach(factura => {
                        const fecha = moment(
                          factura.fechaRangoSeisMeses,
                          'DD/MM/YYYY'
                        )
                        if (fecha.isBefore(minFecha)) {
                          minFecha = fecha
                        }
                      })

                      minFecha = minFecha.format('DD/MM/YYYY')

                      this.$emit('verItems', {
                        minFecha,
                        empresa: this.empresa,
                        tipo: this.tipo
                      })

                      this.dialog = false
                    } else {
                      this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                      })
                    }
                  })
                  .then(res => {
                    if (res.exito) {
                      this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'success'
                      })
                    } else {
                      this.$store.dispatch('show_snackbar', {
                        text: res.message,
                        color: 'error'
                      })
                    }
                  })
              }
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar una empresa.',
          color: 'warning'
        })
      }
      this.$store.state.loading = false
    }
  },

  watch: {
    async archivo() {
      if (this.archivo.length > 0) {
        const reader = new FileReader()

        const file = this.archivo[0]
        this.nombreArchivo = file.name
        reader.onload = async e => {
          const data = new Uint8Array(e.target.result)
          const workbook = read(data, { type: 'array' })
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]
          const jsonData = utils.sheet_to_json(worksheet, { raw: true })

          this.datos = jsonData

          const datosModificados = this.datos.map(dato => {
            let {
              'CUIT Agente Ret./Perc.': cuitProveedor,
              'Importe Ret./Perc.': importe,
              'Denominación o Razón Social': denominacion,
              'Descripción Operación': descripcion,
              'Fecha Comprobante': fechaComprobante,
              'Fecha Registración DJ Ag.Ret.': fechaRegistracion,
              'Descripción Régimen': descripcionRegimen,
              'Número Comprobante': numeroComprobante,
              'Número Certificado': numeroCertificado,
              'Fecha Ret./Perc.': fechaRetPer,
              'Descripción Comprobante': descripcionComprobante,

              Régimen: regimen,
              ...props
            } = dato

            const fechaComprobanteMoment = moment(
              fechaComprobante,
              'DD/MM/YYYY'
            )

            const fechaRangoSeisMeses = fechaComprobanteMoment
              .subtract(6, 'months')
              .format('DD/MM/YYYY')

            return {
              cuitProveedor,
              importe,
              denominacion,
              descripcion,
              fechaComprobante,
              fechaRangoSeisMeses,
              fechaRegistracion,
              descripcionRegimen,
              regimen,
              numeroComprobante,
              numeroCertificado,
              fechaRetPer,
              descripcionComprobante,
              ...props
            }
          })

          datosModificados.forEach(dato => {
            dato.cuitProveedor = dato.cuitProveedor || null
            dato.importe = dato.importe || null
          })
          let dates = datosModificados.map(item =>
            moment(item.fechaComprobante, 'DD/MM/YYYY')
          )
          this.minimaFecha = moment.min(dates)
          this.minimaFecha = this.minimaFecha.startOf('month');

          this.datos = datosModificados
        }
        reader.readAsArrayBuffer(this.archivo[0])
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias'])
  },

  props: {
    value: Boolean
  },

  components: {
    FileDropzone,
    BtnConfirmar
  }
}
</script>
