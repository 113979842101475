<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>
          {{ formulario.nombre_talonario }} - Nro
          {{ formulario.Numero }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="12" md="12" class="py-1">
            <h2>Imágenes</h2>

            <v-data-table
              class="cebra elevation-0 mt-2"
              item-key="id"
              sort-by="id"
              :headers="headers"
              :items="imagenes"
              :loading="load"
              sort-desc
              dense
              :items-per-page="-1"
              hide-default-footer
            >
              <template
                v-for="header in headers.filter(header =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.Nombre`]="{ item }">
                <span>
                  <v-icon
                    v-if="iconoArchivo(item.Nombre) === 'pdf'"
                    style="margin-bottom: 3px"
                    small
                    left
                    color="red"
                  >
                    far fa-file-pdf
                  </v-icon>
                  <v-icon
                    v-else
                    style="margin-bottom: 3px"
                    small
                    left
                    color="info"
                  >
                    fas fa-image
                  </v-icon>
                  {{ item.Nombre }}
                </span>
              </template>

              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  small
                  icon
                  color="success"
                  @click="descargar(item)"
                  title="Descargar"
                >
                  <v-icon class="" small>fas fa-cloud-download-alt</v-icon>
                </v-btn>

                <v-btn
                  small
                  icon
                  color="info"
                  @click="abrirModalVer(item)"
                  title="Ver imagen"
                >
                  <v-icon class="" small>fas fa-eye</v-icon>
                </v-btn>

                <BtnConfirmar
                  v-if="formulario.Estado_id === 3"
                  :icon_button="true"
                  :small="true"
                  icono="fas fa-trash"
                  @action="eliminarArchivo(item)"
                  color="error"
                  title="Eliminar archivo"
                  texto="¿Desea eliminar este archivo?"
                  :disabled="imagenes.length == 1"
                />
              </template>
            </v-data-table>
          </v-col>
          <v-col v-if="formulario.Estado_id === 3">
            <div
              style="
                width: 100%;
                margin-top: 0.6rem;
                display: flex;
                justify-content: end;
                gap: 0.5rem;
              "
            >
              <v-btn color="info" @click="abrirModalAgregarImagenes()">
                <v-icon class="mr-2" small>far fa-image</v-icon>
                Agregar imagenes
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="py-1">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" sm="12" md="12" class="py-3">
            <h2>Historial comentarios</h2>
            <v-data-table
              class="cebra elevation-0 mt-2"
              item-key="id"
              sort-by="id"
              :headers="headersComentarios"
              :items="comentarios"
              :loading="load2"
              sort-desc
              dense
              :items-per-page="-1"
              hide-default-footer
            >
              <template
                v-for="header in headersComentarios.filter(header =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.Comentario`]="{ item }">
                <span v-if="item.Comentario && item.Comentario.length > 0">
                  {{ item.Comentario }}
                </span>
                <span v-else> - </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <div class="d-flex mt-10" style="gap: 8px">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
    <ImgViewer v-model="dialog_img" :img="archivo" :nombre="nombre_imagen" />
    <PDFViewer v-model="dialog_pdf" :pdf="archivo" :nombre="nombre_imagen" />
    <AgregarImagenes
      v-model="modalAgregarImagenes"
      :formulario="formulario"
      @archvosCargados="handleArchivos($event)"
    />
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FileDropzone from '../util/FileDropzone.vue'
import moment from 'moment'
import ImgViewer from '../../components/util/ImgViewer.vue'
import PDFViewer from '../../util/plantillas/PDFViewer'
import AgregarImagenes from './AgregarImagenes.vue'

export default {
  data() {
    return {
      load: false,
      load2: false,
      permiso: false,

      imagenes: [],

      comentarios: [],

      dialog_img: false,
      dialog_pdf: false,
      archivo: null,
      nombre_imagen: '',
      modalAgregarImagenes: false,

      archivos: [],
      tipos_validos: ['.jpeg', 'jpg', '.webp', '.png', '.pdf'],

      headers: [
        { text: 'Nombre', value: 'Nombre' },
        {
          text: 'Fecha',
          value: 'Fecha_log',
          formatter: this.formatDate
        },
        { text: 'Usuario', value: 'Username' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false
        }
      ],

      headersComentarios: [
        { text: 'Comentario', value: 'Comentario' },
        {
          text: 'Estado',
          value: 'nombre_estado'
        },
        { text: 'Usuario', value: 'Username' },
        {
          text: 'Fecha',
          value: 'Fecha_log',
          formatter: this.formatTime
        }
      ]
    }
  },

  methods: {
    async validarPermisos() {
      // OBTENER PERMISOS DE CAJERA
    },

    abrirModalVer(item) {
      this.nombre_imagen = item.Nombre
      if (this.obtenerExtension(this.nombre_imagen) == 'pdf') {
        this.dialog_pdf = true
      } else {
        this.dialog_img = true
      }
      this.archivo = item.Url
    },

    async descargar(item) {
      try {
        const response = await fetch(item.Url)
        if (!response.ok) {
          throw new Error('No se pudo descargar el archivo')
        }
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = item.Nombre
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      } catch (error) {}
    },

    obtenerExtension(archivo) {
      const partes = archivo.split('.')
      return partes.length > 1 ? partes.pop() : ''
    },

    formatDate(date, format) {
      if (!date) return ''
      return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
    },

    formatTime(date, format) {
      if (!date) return ''
      // Formato por defecto con año en YY, día, mes, hora y minutos
      const defaultFormat = format ? format : 'DD/MM/YY HH:mm'
      return moment.utc(date).format(defaultFormat)
    },
    iconoArchivo(nombre) {
      const extension = this.obtenerExtension(nombre)
      return extension === 'pdf' ? 'pdf' : 'imagen'
    },

    abrirModalAgregarImagenes() {
      this.modalAgregarImagenes = true
    },

    async handleArchivos(event) {
      if (event) {
        this.load = true
        await this.$store
          .dispatch('asignacionFormularios/getImagenesFormulario', {
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              this.imagenes = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.load = false
      }
    },

    async eliminarArchivo(item) {
      // elimina un archivo
      if (this.imagenes.length > 1) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('asignacionFormularios/eliminarArchivo', {
            archivo_id: item.Id,
            formulario_id: this.formulario.Id,
            nombreArchivo: item.Nombre,
            estado_id: this.formulario.Estado_id
          })
          .then(async res => {
            if (res.exito) {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.imagenes = this.imagenes.filter(
                imagen => imagen.Id != item.Id
              )

              this.load2 = true

              await this.$store
                .dispatch('asignacionFormularios/getComentarios', {
                  formulario_id: this.formulario.Id
                })
                .then(res => {
                  if (res.exito) {
                    this.comentarios = res.data.reverse()
                  } else {
                    this.$store.dispatch('show_snackbar', {
                      text: res.message,
                      color: 'error'
                    })
                  }
                })
              this.load2 = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'asdasd',// 
          color: 'warning'
        })
      }
    }
  },

  watch: {
    async dialog(val) {
      if (val) {
        this.archivos = []
        this.load = true
        await this.$store
          .dispatch('asignacionFormularios/getImagenesFormulario', {
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              this.imagenes = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.load = false
        this.load2 = true

        await this.$store
          .dispatch('asignacionFormularios/getComentarios', {
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              this.comentarios = res.data.reverse()
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.load2 = false
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  props: {
    value: Boolean,
    tipos_talonarios: Array,
    formulario: Object
  },

  components: {
    BtnConfirmar,
    FileDropzone,
    ImgViewer,
    PDFViewer,
    AgregarImagenes
  }
}
</script>
