var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"700","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',[_vm._v("Auditar recibo N° "+_vm._s(_vm.info.numero))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(" Fecha de pago "),_c('FechaPicker',{attrs:{"hideDetails":true},model:{value:(_vm.data.fecha),callback:function ($$v) {_vm.$set(_vm.data, "fecha", $$v)},expression:"data.fecha"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(" Número Factura "),_c('v-text-field-simplemask',{attrs:{"properties":{
                        'hide-details': true,
                        outlined: true,
                        dense: true,
                        placeholder: '####-########',
                        hideDetails: true
                    },"options":{
                        inputMask: '####-########',
                        outputMask: '####-########',
                        applyAfter: false,
                        alphanumeric: true,
                        empty: ''
                    }},model:{value:(_vm.data.factura),callback:function ($$v) {_vm.$set(_vm.data, "factura", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.factura"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"4","md":"4"}},[_vm._v(" Importe "),_c('v-text-field-money',{attrs:{"type":"number","properties":{
                        prefix: '$',
                        outlined: true,
                        dense: true,
                        hideDetails: true
                    }},model:{value:(_vm.data.importe),callback:function ($$v) {_vm.$set(_vm.data, "importe", $$v)},expression:"data.importe"}})],1),_c('v-col',{staticClass:"py-2 pb-5",attrs:{"cols":"12","sm":"12","md":"12"}},[_vm._v(" Comentario "),_c('v-textarea',{attrs:{"rows":"2","hide-details":"","auto-grow":"","outlined":"","dense":""},model:{value:(_vm.data.comentario),callback:function ($$v) {_vm.$set(_vm.data, "comentario", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.comentario"}})],1),_c('v-col',{staticClass:"py-0 mt-2 mb-3",attrs:{"cols":"12","sm":"2","md":"12"}},[_c('div',{staticStyle:{"width":"100","display":"flex","justify-content":"end"}},[_c('BtnConfirmar',{attrs:{"icono":"fas fa-check","nombre":"Auditar","color":"success"},on:{"action":_vm.auditar}})],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }