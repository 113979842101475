var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"900","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('h4',[_vm._v("Importes voucher")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-btn',{staticStyle:{"position":"absolute","top":"42px","right":"65px","z-index":"1"},attrs:{"color":"success","small":"","fab":"","title":"Nuevo registro"},on:{"click":_vm.abrirModalNuevoVoucher}},[_c('v-icon',[_vm._v("fas fa-plus")])],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.buscar.apply(null, arguments)}}},[_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Empresa "),_c('v-autocomplete',{attrs:{"item-text":"nombre","item-value":"id","hide-details":"","outlined":"","dense":"","clearable":"","items":_vm.familias},on:{"change":_vm.setEmpresas},model:{value:(_vm.filtro.familia),callback:function ($$v) {_vm.$set(_vm.filtro, "familia", $$v)},expression:"filtro.familia"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Marca "),_c('v-autocomplete',{attrs:{"item-text":"nombre","item-value":"id","hide-details":"","outlined":"","dense":"","clearable":"","items":_vm.empresasFiltradas.filter(
                      emp => emp.familia === _vm.filtro.familia
                    )},on:{"change":_vm.setSucursal},model:{value:(_vm.filtro.empresa),callback:function ($$v) {_vm.$set(_vm.filtro, "empresa", $$v)},expression:"filtro.empresa"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_vm._v(" Sucursal "),_c('v-autocomplete',{attrs:{"item-text":"provincia","item-value":"id","hide-details":"","outlined":"","dense":"","clearable":"","items":_vm.sucursalesFiltradas.filter(
                      suc => suc.familia_id === _vm.filtro.familia
                    )},model:{value:(_vm.filtro.sucursal),callback:function ($$v) {_vm.$set(_vm.filtro, "sucursal", $$v)},expression:"filtro.sucursal"}})],1),_c('v-col',{staticClass:"pt-0",staticStyle:{"margin-top":"-22px"},attrs:{"cols":"12","sm":"2","md":"12"}},[_c('BtnFiltro',{attrs:{"loading":_vm.load},on:{"clear":function($event){return _vm.limpiarFiltro()}}})],1)],1)],1)],1),_c('v-col',{staticClass:"py-3",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"cebra elevation-0 mt-2",attrs:{"item-key":"id","sort-by":"id","headers":_vm.headers,"items":_vm.vouchers,"loading":_vm.load,"sort-desc":"","dense":""},scopedSlots:_vm._u([_vm._l((_vm.headers.filter(header =>
                header.hasOwnProperty('formatter')
              )),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:`item.acciones`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","icon":"","color":"warning"},on:{"click":function($event){return _vm.abrirModalEditar(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-pen")])],1)]}}],null,true)})],1)],1)],1)],1),_c('NuevoVoucher',{on:{"actualizar":function($event){return _vm.handleActualizarBusqueda($event)}},model:{value:(_vm.modalNuvoVoucher),callback:function ($$v) {_vm.modalNuvoVoucher=$$v},expression:"modalNuvoVoucher"}}),_c('EditarVoucher',{attrs:{"voucher":_vm.voucherSeleccionado},on:{"actualizar":function($event){return _vm.handleActualizarBusqueda($event)}},model:{value:(_vm.modalEditar),callback:function ($$v) {_vm.modalEditar=$$v},expression:"modalEditar"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }