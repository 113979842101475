<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>
          Agregar imágenes Formulario {{ formulario.nombre_talonario }} - Nro
          {{ formulario.Numero }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="12" md="12" class="py-1">
            <v-col cols="12" sm="12" md="12" class="pb-2 mt-3">
              <FileDropzone
                :files="archivos"
                :tipos="tipos_validos"
                :multiple="true"
                :max_size="25"
              />
            </v-col>
          </v-col>
        </v-row>
        <div class="d-flex mt-10">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
          <div class="ml-2">
            <BtnConfirmar @action="guardarCambios()" :nombre="`Confirmar`" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FileDropzone from '../util/FileDropzone.vue'
import { getBase64 } from '../../util/utils'

// ESTE MODAL EMITIRIA UN ARRAY DE IMAGENES Y NOMBRE DE IMAGENES PARA POSTERIORMENTE SUBIR AL GUARDAR EN MODAL VERDETALLEIMAGENES

export default {
  data() {
    return {
      load: false,
      permiso: false,

      imagenes: [],

      comentarios: [],

      data: {
        comentario: ''
      },

      dialog_img: false,
      dialog_pdf: false,
      nombre_imagen: '',

      modalComentarios: false,

      archivos: [],
      tipos_validos: ['.jpeg', '.jpg', '.webp', '.png', '.pdf']
    }
  },

  methods: {
    async validarPermisos() {
      // OBTENER PERMISOS DE CAJERA
    },

    async guardarCambios() {
      // PREGUNTO EL ESTADO
      // pasa a pendiente validar
      // FOTO REQUERIDA
      if (this.archivos.length > 0) {
        const nombreArchivos = []
        const archivos = []

        if (this.archivos.length > 0) {
          for (const archivo of this.archivos) {
            nombreArchivos.push(archivo.name)
            const file = await getBase64(archivo)
            archivos.push(file)
          }
        }
        this.$store.state.loading = true
        await this.$store
          .dispatch('asignacionFormularios/agregarImagenes', {
            archivos,
            nombreArchivos,
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              if(res.feedbackFallidos.length > 0){
                this.$swal.fire({
                  icon: 'info',
                  title: res.message,
                  html: `<strong>${res.feedbackFallidos}</strong>`,
                  width: '450px'
                })
              } else {
                this.$swal.fire({
                  icon: 'info',
                  title: res.message,
                })
              }
              this.$emit('archvosCargados', true)
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false

        this.dialog = false

        // EMITIR LOS NOMBRES Y LAS IMAGENES
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes subir al menos una imagen.',
          color: 'warning'
        })
        return
      }
    }
  },

  watch: {
    async dialog(val) {
      if (val) {
        this.archivos = []
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  props: {
    value: Boolean,
    formulario: Object
  },

  components: {
    BtnConfirmar,
    FileDropzone
  }
}
</script>
