<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>
          Anular formulario {{ formulario.nombre_talonario }} -
          {{ formulario.Numero }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="12" md="12" class="pb-2">
            <FileDropzone
              :files="archivos"
              :tipos="tipos_validos"
              :multiple="true"
              :max_size="25"
            />
          </v-col>

          <v-col cols="11" sm="11" md="11" class="py-3">
            <v-textarea
              v-model.trim="data.comentario"
              label="Comentario"
              rows="2"
              hide-details
              auto-grow
              outlined
              dense
            ></v-textarea>
          </v-col>
          <v-col cols="1" sm="1" md="1" class="py-3">
            <v-btn
              :disabled="comentarios.length == 0"
              small
              icon
              color="info"
              @click="abrirModalComentarios"
              title="Historial de comentarios"
            >
              <v-icon class="" small>fas fa-info-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
          <div class="ml-2">
            <BtnConfirmar
              @action="anularFormulario()"
              icono="fas fa-trash"
              nombre="Anular"
              color="error"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <ModalComentarios v-model="modalComentarios" :comentarios="comentarios" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FechaPicker from '../util/FechaPicker.vue'
import FileDropzone from '../util/FileDropzone.vue'
import { getBase64 } from '../../util/utils'
import ModalComentarios from './ModalComentarios.vue'

export default {
  data() {
    return {
      load: false,
      permiso: true,

      archivos: [],
      tipos_validos: ['.jpeg', '.jpg', '.webp', '.png', '.pdf'],

      data: {
        tipo: null,
        sucursal: null,
        numero: null,
        usuario: null,
        patente: '',
        cliente: '',
        empresa: null,
        talonario_id: null,
        comentario: ''
      },

      imagenes: [],
      comentarios: [],
      usuarios: [],

      numeroValido: false,
      modalComentarios: false
    }
  },
  methods: {
    async anularFormulario() {
      const archivos = []
      const nombreArchivos = []

      if (this.archivos.length > 0 && this.data.comentario.length > 0) {
        for (const archivo of this.archivos) {
          nombreArchivos.push(archivo.name)
          const file = await getBase64(archivo)
          archivos.push(file)
        }
        this.$store.state.loading = true
        if (this.permiso_anular) {
          await this.$store
            .dispatch('asignacionFormularios/anularFormulario', {
              archivos,
              comentario: this.data.comentario,
              formulario_id: this.formulario.Id,
              nombreArchivos
            })
            .then(res => {
              if (res.exito) {
                if (res.archivosFallidos.length > 0) {
                  // verificar los que fallaron
                  let tituloFallidos =
                    '<h3>Los siguientes archivos no se subieron:</h3><br/>'
                  let mensajeFallidos = ''
                  res.archivosFallidos.forEach(nombre => {
                    mensajeFallidos =
                      mensajeFallidos + `<strong>${nombre}</strong>`
                  })
                  let mensaje = ''
                  if (mensajeFallidos.length > 0) {
                    mensaje = tituloFallidos + mensajeFallidos
                  }

                  this.$swal.fire({
                    icon: 'info',
                    title: res.message,
                    html: mensaje
                  })
                } else {
                  this.archivos = []
                  this.$swal.fire({
                    icon: 'success',
                    title: res.message
                  })
                }
                this.dialog = false
              } else {
                // verificar los que fallaron
                let tituloFallidos =
                  '<h3>Los siguientes archivos no se subieron:</h3><br/>'
                let mensajeFallidos = ''
                res.archivosFallidos.forEach(nombre => {
                  mensajeFallidos =
                    mensajeFallidos + `<strong>${nombre}</strong>`
                })
                let mensaje = ''
                if (mensajeFallidos.length > 0) {
                  mensaje = tituloFallidos + mensajeFallidos
                }


                let tituloFeedbackFallidos =
                  '<br/><h3>Mensaje:</h3><br/>'
                let mensajeFeedbackFallidos = ''
                res.feedbackFallidos.forEach(nombre => {
                  mensajeFeedbackFallidos =
                  mensajeFeedbackFallidos + `<strong>${nombre}</strong>`
                })
                let mensajeFeedback = ''
                if (mensajeFallidos.length > 0) {
                  mensajeFeedback = tituloFeedbackFallidos + mensajeFeedbackFallidos
                }
                this.$swal.fire({
                  icon: 'info',
                  title: res.message,
                  html: mensaje + mensajeFeedback
                })
              }
            })
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'No tienes permisos para realizar esta acción.',
            color: 'warning'
          })
        }
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes subir al menos una imagen y un comentario.',
          color: 'warning'
        })
      }
    },

    async abrirModalComentarios() {
      this.modalComentarios = true
    }
  },

  watch: {
    async dialog(val) {
      if (val) {
        this.data = {
          tipo: null,
          numero: null,
          comentario: ''
        }
        this.archivos = []
        this.load = true
        await this.$store
          .dispatch('asignacionFormularios/getImagenesFormulario', {
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              this.imagenes = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        await this.$store
          .dispatch('asignacionFormularios/getComentarios', {
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              this.comentarios = res.data.reverse()
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.load = false
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  props: {
    value: Boolean,
    tipos_talonarios: Array,
    formulario: {
      type: Object,
      default: {
        Numero: 0,
        nombre_talonario: ''
      }
    },
    permiso_anular: {
      type: Boolean,
      default: false
    }
  },

  components: {
    BtnConfirmar,
    FileDropzone,
    ModalComentarios
  }
}
</script>
