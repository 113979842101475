<template>
  <v-dialog v-model="dialog" max-width="900" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Importes voucher</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-btn
          style="position: absolute; top: 42px; right: 65px; z-index: 1"
          color="success"
          small
          fab
          title="Nuevo registro"
          @click="abrirModalNuevoVoucher"
        >
          <v-icon>fas fa-plus</v-icon>
        </v-btn>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="12" md="12" class="pt-3">
            <v-form @submit.prevent="buscar">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.familia"
                    item-text="nombre"
                    item-value="id"
                    hide-details
                    outlined
                    dense
                    clearable
                    :items="familias"
                    @change="setEmpresas"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre"
                    item-value="id"
                    hide-details
                    outlined
                    dense
                    clearable
                    :items="
                      empresasFiltradas.filter(
                        emp => emp.familia === filtro.familia
                      )
                    "
                    @change="setSucursal"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="provincia"
                    item-value="id"
                    hide-details
                    outlined
                    dense
                    clearable
                    :items="
                      sucursalesFiltradas.filter(
                        suc => suc.familia_id === filtro.familia
                      )
                    "
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="12"
                  class="pt-0"
                  style="margin-top: -22px"
                >
                  <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col cols="12" sm="12" md="12" class="py-3">
            <v-data-table
              class="cebra elevation-0 mt-2"
              item-key="id"
              sort-by="id"
              :headers="headers"
              :items="vouchers"
              :loading="load"
              sort-desc
              dense
            >
              <template
                v-for="header in headers.filter(header =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>

              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  small
                  icon
                  color="warning"
                  @click="abrirModalEditar(item)"
                >
                  <v-icon class="" small>fas fa-pen</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <NuevoVoucher
      v-model="modalNuvoVoucher"
      @actualizar="handleActualizarBusqueda($event)"
    />
    <EditarVoucher @actualizar="handleActualizarBusqueda($event)" v-model="modalEditar" :voucher="voucherSeleccionado" />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../util/BtnFiltro.vue'
import NuevoVoucher from './NuevoVoucher.vue'
import moment from 'moment'
import { format_money } from '../../../util/utils'
import EditarVoucher from './EditarVoucher.vue'

export default {
  data() {
    return {
      load: false,
      modalNuvoVoucher: false,
      modalEditar: false,

      sucursalesFiltradas: [],
      empresasFiltradas: [],

      vouchers: [],
      voucherSeleccionado: {},

      data: {
        tipo_nombre: '',
        Nro_desde: 0,
        Nro_hasta: 0
      },

      filtro: {
        familia: null,
        empresa: null,
        sucursal: null
      },

      headers: [
        { text: 'Empresa', value: 'familia_nombre' },
        { text: 'Sucursal', value: 'sucursal_nombre' },
        { text: 'Importe', value: 'Importe', formatter: format_money },
        { text: 'Direccion', value: 'Direccion' },
        {
          text: 'Fecha creación',
          value: 'Fecha_crea',
          formatter: this.formatDate
        },
        { text: 'Acciones', value: 'acciones', sortable: false }
      ]
    }
  },

  methods: {
    async buscar() {
      await this.$store
        .dispatch('vouchersCombustible/getVouchers', {
          familia: this.filtro.familia,
          empresa: this.filtro.empresa,
          sucursal: this.filtro.sucursal
        })
        .then(res => {
          if (res.exito) {
            this.vouchers = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
    },

    setEmpresas() {
      const empresa = this.empresas.filter(
        emp => emp.familia === this.filtro.familia
      )
      empresa.length === 1 ? (this.filtro.empresa = empresa[0].id) : null
    },

    setSucursal() {
      const sucursal = this.sucursales.filter(
        suc => suc.familia_id === this.filtro.familia
      )
      sucursal.length === 1 ? (this.filtro.sucursal = sucursal[0].id) : null
    },

    abrirModalNuevoVoucher() {
      // ANTES DE ABRIR ESTE MODAL NECESITO SABER SI NO EXISTE UNO PARA ESA EMPRESA
      this.modalNuvoVoucher = true
    },

    abrirModalEditar(item){
      this.voucherSeleccionado = item
      this.modalEditar = true
    },

    async handleActualizarBusqueda(event){
      if(event){
        await this.buscar()
      }
    },

    limpiarFiltro() {
      this.filtro = {
        familia: null,
        empresa: null,
        sucursal: null
      }
    },

    formatDate(date, format) {
      if (!date) return ''
      return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias', 'empresas']),
    ...mapState('genericos', ['sucursales'])
  },

  props: {
    value: Boolean
  },

  async created() {
    if (this.familias.length == 1) this.filtro.familia = this.familias[0].id
    await this.$store.dispatch('genericos/get_sucursales')
    this.sucursalesFiltradas = this.sucursales
    this.empresasFiltradas = this.empresas
  },

  components: {
    BtnFiltro,
    NuevoVoucher,
    EditarVoucher
  }
}
</script>
