<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>
          Formulario {{ formulario.nombre_talonario }} - Nro
          {{ formulario.Numero }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="12" md="12" class="py-1">
            <h2>Imágenes</h2>

            <v-data-table
              class="cebra elevation-0 mt-2"
              item-key="id"
              sort-by="id"
              :headers="headers"
              :items="imagenes"
              :items-per-page="-1"
              hide-default-footer
              :loading="load"
              sort-desc
              dense
            >
              <template
                v-for="header in headers.filter(header =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`item.Nombre`]="{ item }">
                <span>
                  <v-icon
                    v-if="iconoArchivo(item.Nombre) === 'pdf'"
                    style="margin-bottom: 3px"
                    small
                    left
                    color="red"
                  >
                    far fa-file-pdf
                  </v-icon>
                  <v-icon
                    v-else
                    style="margin-bottom: 3px"
                    small
                    left
                    color="info"
                  >
                    fas fa-image
                  </v-icon>
                  {{ item.Nombre }}
                </span>
              </template>

              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  small
                  icon
                  color="success"
                  @click="descargar(item)"
                  title="Descargar"
                >
                  <v-icon class="" small>fas fa-cloud-download-alt</v-icon>
                </v-btn>

                <v-btn
                  small
                  icon
                  color="info"
                  @click="abrirModalVer(item)"
                  title="Ver imagen"
                >
                  <v-icon class="" small>fas fa-eye</v-icon>
                </v-btn>

                <BtnConfirmar
                  :icon_button="true"
                  :small="true"
                  icono="fas fa-trash"
                  @action="eliminarArchivo(item)"
                  color="error"
                  title="Eliminar archivo"
                  texto="¿Desea eliminar este archivo?"
                  :disabled="formulario.Estado_id != 1"
                />
              </template>
            </v-data-table>
            <v-col cols="12" sm="12" md="12" class="pb-2 mt-3">
              <FileDropzone
                :files="archivos"
                :tipos="tipos_validos"
                :multiple="true"
                :max_size="25"
              />
            </v-col>
          </v-col>

          <v-col cols="11" sm="11" md="11" class="py-3">
            <v-textarea
              v-model.trim="data.comentario"
              label="Comentario"
              rows="2"
              hide-details
              auto-grow
              outlined
              dense
            ></v-textarea>
          </v-col>
          <v-col cols="1" sm="1" md="1" class="py-3">
            <v-btn
              :disabled="comentarios.length == 0"
              small
              icon
              color="info"
              @click="abrirModalComentarios"
              title="Historial de comentarios"
            >
              <v-icon class="" small>fas fa-info-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="d-flex mt-10">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
          <div
            v-if="
              !(
                formulario.Estado_id === 1 ||
                formulario.Estado_id === 2 ||
                formulario.Estado_id === 4 ||
                formulario.Estado_inicial !== 1
              )
            "
            class="ml-2"
          >
            <BtnConfirmar
              @action="rechazar()"
              nombre="Rechazar"
              color="warning"
              icono="fas fa-ban"
            />
          </div>
          <div class="ml-2">
            <BtnConfirmar
              @action="guardarCambios()"
              :nombre="`${
                formulario.Estado_id === 1 ? 'Confirmar' : 'Validar'
              }`"
              :icono="`${
                formulario.Estado_id === 1 ? 'fas fa-save' : 'fas fa-check'
              }`"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <ImgViewer v-model="dialog_img" :img="archivo" :nombre="nombre_imagen" />
    <PDFViewer v-model="dialog_pdf" :pdf="archivo" :nombre="nombre_imagen" />
    <ModalComentarios v-model="modalComentarios" :comentarios="comentarios" />
  </v-dialog>
</template>

<script>
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FileDropzone from '../util/FileDropzone.vue'
import moment from 'moment'
import ImgViewer from '../../components/util/ImgViewer.vue'
import PDFViewer from '../../util/plantillas/PDFViewer'
import { getBase64 } from '../../util/utils'
import ModalComentarios from './ModalComentarios.vue'

export default {
  data() {
    return {
      load: false,
      permiso: false,

      imagenes: [],

      comentarios: [],

      data: {
        comentario: ''
      },

      dialog_img: false,
      dialog_pdf: false,
      archivo: null,
      nombre_imagen: '',

      modalComentarios: false,

      archivos: [],
      tipos_validos: ['.jpeg', '.jpg', '.webp', '.png', '.pdf'],

      headers: [
        { text: 'Nombre', value: 'Nombre' },
        {
          text: 'Fecha',
          value: 'Fecha_log',
          formatter: this.formatDate
        },
        { text: 'Usuario', value: 'Username' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false
        }
      ],

      headersComentarios: [
        { text: 'Comentario', value: 'Comentario' },
        {
          text: 'Estado',
          value: 'nombre_estado'
        },
        { text: 'Usuario', value: 'Username' },
        {
          text: 'Fecha',
          value: 'Fecha_log',
          formatter: this.formatDate
        }
      ]
    }
  },

  methods: {
    async guardarCambios() {
      const estadoPendiente = this.estados.find(estado => estado.Id === 3)
      const tipoTalonario = this.tipos_talonarios.find(
        tipo => tipo.Nombre === this.formulario.tipo_talonario
      )

      const estadoValidado = this.estados.find(estado => estado.Id === 4)

      // PREGUNTO EL ESTADO
      if (this.formulario.Estado_id === 1) {
        // pasa a pendiente validar
        // FOTO REQUERIDA
        // VALIDAR FOTO

        const nombreArchivos = []
        const archivos = []

        if (estadoPendiente.Imagen === 1 && tipoTalonario.Imagen === 1) {
          if (this.archivos.length > 0 || this.imagenes.length > 0) {
            if (this.archivos.length > 0) {
              for (const archivo of this.archivos) {
                nombreArchivos.push(archivo.name)
                const file = await getBase64(archivo)
                archivos.push(file)
              }
            }
            this.$store.state.loading = true
            await this.$store
              .dispatch('asignacionFormularios/setPendiente', {
                comentario: this.data.comentario,
                formulario_id: this.formulario.Id,
                archivos,
                nombreArchivos
              })
              .then(res => {
                if (res.exito) {
                  this.$swal.fire({
                    icon: 'success',
                    title: res.message
                  })
                  this.$emit('buscar', true)
                  this.dialog = false
                } else {
                  this.$swal.fire({
                    icon: 'warning',
                    title: res.message,
                    text: res.archivosFallidos
                  })
                  this.dialog = false
                }
              })
            this.$store.state.loading = false
          } else {
            this.$store.dispatch('show_snackbar', {
              text: 'Debes subir al menos una imagen.',
              color: 'warning'
            })
            return
          }
        } else {
          this.$store.state.loading = true
          await this.$store
            .dispatch('asignacionFormularios/setPendiente', {
              comentario: this.data.comentario,
              formulario_id: this.formulario.Id,
              archivos,
              nombreArchivos
            })
            .then(res => {
              if (res.exito) {
                this.$swal.fire({
                  icon: 'success',
                  title: res.message
                })
                this.$emit('buscar', true)
                this.dialog = false
              } else {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.message,
                  text: res.archivosFallidos
                })
                this.dialog = false
              }
            })
          this.$store.state.loading = false
        }
        // if (this.archivos.length > 0 || this.imagenes.length > 0) {
        //   const nombreArchivos = []
        //   const archivos = []

        //   if (this.archivos.length > 0) {
        //     for (const archivo of this.archivos) {
        //       nombreArchivos.push(archivo.name)
        //       const file = await getBase64(archivo)
        //       archivos.push(file)
        //     }
        //   }
        //   this.$store.state.loading = true
        //   await this.$store
        //     .dispatch('asignacionFormularios/setPendiente', {
        //       comentario: this.data.comentario,
        //       formulario_id: this.formulario.Id,
        //       archivos,
        //       nombreArchivos
        //     })
        //     .then(res => {
        //       if (res.exito) {
        //         this.$swal.fire({
        //           icon: 'success',
        //           title: res.message
        //         })
        //         this.$emit('buscar', true)
        //         this.dialog = false
        //       } else {
        //         this.$swal.fire({
        //           icon: 'warning',
        //           title: res.message,
        //           text: res.archivosFallidos
        //         })
        //         this.dialog = false
        //       }
        //     })
        //   this.$store.state.loading = false
        // } else {
        //   this.$store.dispatch('show_snackbar', {
        //     text: 'Debes subir al menos una imagen.',
        //     color: 'warning'
        //   })
        //   return
        // }
      }

      if (this.formulario.Estado_id === 3) {
        const nombreArchivos = []
        const archivos = []

        // control imagen
        if (estadoPendiente.Imagen === 1 && tipoTalonario.Imagen === 1) {
          if (this.archivos.length > 0 || this.imagenes.length > 0) {
            if (this.archivos.length > 0) {
              for (const archivo of this.archivos) {
                nombreArchivos.push(archivo.name)
                const file = await getBase64(archivo)
                archivos.push(file)
              }
            }

            this.$store.state.loading = true
            await this.$store
              .dispatch('asignacionFormularios/setValidado', {
                comentario: this.data.comentario,
                formulario_id: this.formulario.Id,
                archivos,
                nombreArchivos
              })
              .then(res => {
                if (res.exito) {
                  if (res.feedbackFallidos && res.feedbackFallidos.length > 0) {
                    this.$swal.fire({
                      icon: 'success',
                      title: res.message,
                      html: `<strong>${res.feedbackFallidos}</strong>`,
                      width: '450px'
                    })
                  } else {
                    this.$swal.fire({
                      icon: 'success',
                      title: res.message
                    })
                  }
                  this.$emit('buscar', true)
                  this.dialog = false
                } else {
                  this.$swal.fire({
                    icon: 'warning',
                    title: res.message,
                    text: res.archivosFallidos
                  })
                  this.dialog = false
                }
              })
            this.$store.state.loading = false
          } else {
            this.$store.dispatch('show_snackbar', {
              text: 'Debes subir al menos una imagen.',
              color: 'warning'
            })
          }
        } else {
          this.$store.state.loading = true
          await this.$store
            .dispatch('asignacionFormularios/setValidado', {
              comentario: this.data.comentario,
              formulario_id: this.formulario.Id,
              archivos,
              nombreArchivos
            })
            .then(res => {
              if (res.exito) {
                if (res.feedbackFallidos && res.feedbackFallidos.length > 0) {
                  this.$swal.fire({
                    icon: 'success',
                    title: res.message,
                    html: `<strong>${res.feedbackFallidos}</strong>`,
                    width: '450px'
                  })
                } else {
                  this.$swal.fire({
                    icon: 'success',
                    title: res.message
                  })
                }
                this.$emit('buscar', true)
                this.dialog = false
              } else {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.message,
                  text: res.archivosFallidos
                })
                this.dialog = false
              }
            })
          this.$store.state.loading = false
        }
      }
    },

    async rechazar() {
      // PREGUNTO EL ESTADO
      if (this.formulario.Estado_id === 3) {
        // ESTADO PENDIENTE PASAR A ASIGNADO
        if (this.data.comentario.length > 0) {
          // SETEAR EL ESTADO

          this.$store.state.loading = true
          await this.$store
            .dispatch('asignacionFormularios/setAsignado', {
              comentario: this.data.comentario,
              formulario_id: this.formulario.Id
            })
            .then(res => {
              if (res.exito) {
                this.$swal.fire({
                  icon: 'success',
                  text: res.message
                })
                this.$emit('buscar', true)
                this.dialog = false
              } else {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'error'
                })
              }
            })
          this.$store.state.loading = false
        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'Debes enviar un comentario.',
            color: 'warning'
          })
        }
      }
    },

    async eliminarArchivo(item) {
      if (this.imagenes.length > 1 || this.formulario.Estado_id == 1) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('asignacionFormularios/eliminarArchivo', {
            archivo_id: item.Id,
            permiso: this.permiso,
            formulario_id: this.formulario.Id,
            nombreArchivo: item.Nombre,
            Usuario_log: item.Usuario_log,
            estado_id: this.formulario.Estado_id
          })
          .then(res => {
            if (res.exito) {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success'
              })
              this.imagenes = this.imagenes.filter(
                imagen => imagen.Id != item.Id
              )
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      }
    },

    abrirModalVer(item) {
      this.nombre_imagen = item.Nombre
      if (this.obtenerExtension(this.nombre_imagen) == 'pdf') {
        this.dialog_pdf = true
      } else {
        this.dialog_img = true
      }
      this.archivo = item.Url
    },

    async descargar(item) {
      try {
        const response = await fetch(item.Url)
        if (!response.ok) {
          throw new Error('No se pudo descargar el archivo')
        }
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = item.Nombre
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      } catch (error) {}
    },

    obtenerExtension(archivo) {
      const partes = archivo.split('.')
      return partes.length > 1 ? partes.pop() : ''
    },

    formatDate(date, format) {
      if (!date) return ''
      return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
    },
    iconoArchivo(nombre) {
      const extension = this.obtenerExtension(nombre)
      return extension === 'pdf' ? 'pdf' : 'imagen'
    },

    async abrirModalComentarios() {
      this.modalComentarios = true
    }
  },

  watch: {
    async dialog(val) {
      if (val) {
        this.data.comentario = ''
        this.archivos = []
        this.load = true
        await this.$store
          .dispatch('asignacionFormularios/getImagenesFormulario', {
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              this.imagenes = res.data
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        await this.$store
          .dispatch('asignacionFormularios/getComentarios', {
            formulario_id: this.formulario.Id
          })
          .then(res => {
            if (res.exito) {
              this.comentarios = res.data.reverse()
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.load = false
      }
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  props: {
    value: Boolean,
    tipos_talonarios: Array,
    formulario: Object,
    estados: Array
  },

  components: {
    BtnConfirmar,
    FileDropzone,
    ImgViewer,
    PDFViewer,
    ModalComentarios
  }
}
</script>
