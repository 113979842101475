<template>
  <v-dialog v-model="dialog" max-width="800" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Subir archivo</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="pt-5 align-center">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete
              v-model="filtro.empresa"
              item-text="Nombre"
              return-object
              hide-details
              outlined
              dense
              clearable
              :items="empresas"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="py-1">
            <FileDropzone
              :files.sync="archivo"
              :tipos="tipos_validos"
              :multiple="false"
              :max_size="25"
            />
          </v-col>

          <v-col cols="12" class="py-1">
            <div style="display: flex; justify-content: end">
              <BtnConfirmar
                @action="subir"
                nombre="subir"
                icono="fas fa-file-upload"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import FileDropzone from "../util/FileDropzone.vue";
import BtnConfirmar from "../util/BtnConfirmar.vue";
import { read, utils } from "xlsx";
import moment from "moment";

export default {
  data() {
    return {
      archivo: [],
      cuit_cuil: "",
      tipos_validos: [".xls", ".xlsx", ".ods"],
      filtro: {
        empresa: null,
      },
      empresas: [],
      datos: [],
    };
  },

  methods: {
    async subir() {
      if (this.cuit_cuil == this.filtro.empresa.Cuit) {
        if (this.datos[0].Fecha && this.datos[0].NroDocEmisor) {
          this.$store.state.loading = true;

          if (this.filtro.empresa) {
            await this.$store
              .dispatch("comprobantesAfip/enviarDatos", {
                data: this.datos,
                empresa: this.filtro.empresa.Id,
              })
              .then(async (res) => {
                if (res.exito) {
                  if (res.realizoInserciones) {
                    let minFecha = moment(this.datos[0].Fecha, "DD/MM/YYYY");
                    let maxFecha = moment(this.datos[0].Fecha, "DD/MM/YYYY");
                    this.datos.forEach((factura) => {
                      const fecha = moment(factura.Fecha, "DD/MM/YYYY");
                      if (fecha.isBefore(minFecha)) {
                        minFecha = fecha;
                      }
                      if (fecha.isAfter(maxFecha)) {
                        maxFecha = fecha;
                      }
                    });

                    minFecha = minFecha.format('DD/MM/YYYY')
                    maxFecha = maxFecha.format('DD/MM/YYYY')

                    await this.$store
                      .dispatch("comprobantesAfip/procesarDatos", {
                        reprocesar: false,
                        fecha_desde: minFecha,
                        fecha_hasta: maxFecha
                      })
                      .then((res) => {
                        if (res.exito) {
                          this.$swal.fire({
                            icon: "success",
                            title: res.message,
                          });
                          this.dialog = false;
                        } else {
                          this.$store.dispatch("show_snackbar", {
                            text: res.message,
                            color: "error",
                          });
                        }
                      });
                  } else {
                    this.$store.dispatch("show_snackbar", {
                      text: "Los comprobantes ya existen en la base de datos.",
                      color: "success",
                    });
                    this.archivo = [];
                    this.filtro.empresa = null;
                    this.dialog = false;
                  }
                  this.archivo = [];
                  this.filtro.empresa = null;
                } else {
                  this.$store.dispatch("show_snackbar", {
                    text: res.message,
                    color: "error",
                  });
                  this.archivo = [];
                  this.filtro.empresa = null;
                }
              });
          } else {
            this.$store.dispatch("show_snackbar", {
              text: "Debes seleccionar una empresa.",
              color: "warning",
            });
          }
          this.$store.state.loading = false;
        } else {
          this.$store.dispatch("show_snackbar", {
            text: "El formato del archivo no es válido",
            color: "warning",
          });
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "El archivo no pertenece a la empresa seleccionada.",
          color: "error",
        });
      }
    },
  },

  watch: {
    async archivo() {
      if (this.archivo.length > 0) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const allRows = utils.sheet_to_json(worksheet, { header: 1 });

          let titulo = allRows[0][0];
          typeof titulo == "string"
            ? (titulo = titulo.trim())
            : (titulo = null);

          const headers = allRows[1];
          const dataRows = allRows.slice(2);

          const jsonData = dataRows.map((row) => {
            let obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index];
            });
            return obj;
          });

          this.datos = jsonData;
          this.cuit_cuil = titulo.slice(-11);

          const datosModificados = this.datos.map((dato) => {
            let {
              Fecha,
              Tipo,
              "Punto de Venta": PuntoDeVenta,
              "Número Desde": NumeroDesde,
              "Número Hasta": NumeroHasta,
              "Cód. Autorización": CodAutorizacion,
              "Tipo Doc. Emisor": TipoDocEmisor,
              "Nro. Doc. Emisor": NroDocEmisor,
              "Denominación Emisor": DenominacionEmisor,
              "Tipo Cambio": TipoCambio,
              Moneda,
              "Imp. Neto Gravado": ImpNetoGravado,
              "Imp. Neto No Gravado": ImpNetoNoGravado,
              "Imp. Op. Exentas": ImpOpExentas,
              "Otros Tributos": OtrosTributos,
              IVA,
              "Imp. Total": ImpTotal,
              ...props
            } = dato;

            return {
              Fecha,
              Tipo,
              PuntoDeVenta,
              NumeroDesde,
              NumeroHasta,
              CodAutorizacion,
              TipoDocEmisor,
              NroDocEmisor,
              DenominacionEmisor,
              TipoCambio,
              Moneda,
              ImpNetoGravado,
              ImpNetoNoGravado,
              ImpOpExentas,
              OtrosTributos,
              IVA,
              ImpTotal,
              ...props,
            };
          });

          datosModificados.forEach((dato) => {
            dato.NumeroHasta = dato.NumeroHasta || null;
            dato.ImpNetoGravado = dato.ImpNetoGravado || null;
            dato.ImpOpExentas = dato.ImpOpExentas || null;
            dato.IVA = dato.IVA || null;
            dato.ImpTotal = dato.ImpTotal || null;
            dato.ImpNetoNoGravado = dato.ImpNetoNoGravado || null;
            dato.CodAutorizacion = dato.CodAutorizacion || null;
            dato.NroDocEmisor = dato.NroDocEmisor || null;
            dato.DenominacionEmisor = dato.DenominacionEmisor || null;
            dato.TipoCambio = dato.TipoCambio || null;
            dato.Moneda = dato.Moneda || null;
            dato.OtrosTributos = dato.OtrosTributos || null;
          });

          this.datos = datosModificados;
        };

        reader.readAsArrayBuffer(this.archivo[0]);
      }
    },
  },

  async created() {
    await this.$store.dispatch("comprobantesAfip/getFamilias").then((res) => {
      if (res.exito) {
        this.empresas = res.data;

        this.empresas = this.empresas.filter((empresa) =>
          this.familias.some((familia) => familia.id == empresa.Id)
        );

        this.empresas.forEach((empresa) => {
          empresa.Cuit = empresa.Cuit.replace(/-/g, "");
        });

        if (this.empresas.length == 1) {
          this.filtro.empresa = this.empresas[0].Id;
        }
      } else {
        this.$store.dispatch("show_snackbar", {
          text: res.message,
          color: "error",
        });
      }
    });
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState(["familias"]),
  },

  props: {
    value: Boolean,
  },

  components: {
    FileDropzone,
    BtnConfirmar,
  },
};
</script>
