<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-expansion-panel-header
              class="py-0 px-4 text-h5"
              style="cursor: default"
            >
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>

            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.familia"
                      item-text="nombre"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="familias"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="filtro.sucursal"
                      item-text="provincia"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="
                        sucursales.filter(
                          suc => suc.familia_id === filtro.familia
                        )
                      "
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Tipo
                    <v-autocomplete
                      v-model="filtro.tipo"
                      item-text="Nombre"
                      item-value="Id"
                      hide-details
                      outlined
                      dense
                      :items="tipos_talonarios"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="filtro.estado"
                      item-text="Nombre"
                      item-value="Id"
                      hide-details
                      outlined
                      dense
                      :items="estados"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="3" xl="2" class="py-1">
                    Numero
                    <v-text-field
                      v-model.trim="filtro.numero"
                      hide-details
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="3" xl="2" class="py-1">
                    Patente
                    <v-text-field
                      v-model.trim="filtro.patente"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" sm="3" xl="2" class="py-1">
                    Cliente
                    <v-text-field
                      v-model.trim="filtro.cliente"
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="12"
                    class="pt-0"
                    style="margin-top: -22px"
                  >
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-2">
          <v-data-table
            class="cebra elevation-0 mt-2"
            item-key="id"
            sort-by="id"
            :headers="headers"
            :items="formularios"
            :loading="load"
            sort-desc
            dense
          >
            <template
              v-for="header in headers.filter(header =>
                header.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${header.value}`]="{ value }"
            >
              {{ header.formatter(value) }}
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
              <v-btn
                v-if="!(item.Estado_id === 2 || item.Estado_id === 4)"
                small
                icon
                :color="item.Estado_id === 1 ? 'warning' : 'success'"
                @click="abrirModalEditar(item)"
                :title="item.Estado_id === 1 ? 'Editar' : 'Validar'"
                :disabled="item.Estado_id === 3 && !permisos.validar"
              >
                <v-icon class="" small>{{
                  item.Estado_id === 1 ? 'fas fa-pen' : 'fas fa-check'
                }}</v-icon>
              </v-btn>
              <v-btn
                small
                icon
                color="info"
                @click="abrirModalVerDetalles(item)"
                title="Ver detalles"
              >
                <v-icon class="" small>fas fa-eye</v-icon>
              </v-btn>

              <v-btn
                v-if="!(item.Estado_id === 2 || item.Estado_id === 4)"
                small
                icon
                color="error"
                @click="abrirModalAnular(item)"
                title="Anular"
                :disabled="!permisos.anular"
              >
                <v-icon class="" small>fas fa-trash</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div
      style="
        width: 100%;
        margin-top: 0.6rem;
        display: flex;
        justify-content: end;
        gap: 0.5rem;
      "
    >
      <v-btn v-if="permisos.asignar" color="success" @click="abrirModalAsignar()" title="Asignar">
        <v-icon class="mr-2" small>far fa-check-circle</v-icon>
        Asignar
      </v-btn>
    </div>
    <ModalEditarFormulario
      v-model="modalEditar"
      :tipos_talonarios="tipos_talonarios"
      :formulario="formulario"
      @buscar="handleActualizar($event)"
      :estados="estados"
    />

    <ModalAsignar v-model="modalAsignar" :tipos_talonarios="tipos_talonarios" />
    <ModalAnular
      v-model="modalAnular"
      :tipos_talonarios="tipos_talonarios"
      :formulario="formulario"
      :permiso_anular="permisos.anular"
    />
    <VerDetalleImagenes v-model="modalVer" :formulario="formulario" />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'

import { mapState } from 'vuex'
import NuevoTalonario from '../../components/administracion/NuevoTalonario.vue'
import EditarTalonario from '../../components/administracion/EditarTalonario.vue'
import ModalAsignar from '../../components/administracion/ModalAsignar.vue'
import ModalAnular from '../../components/administracion/ModalAnular.vue'
import moment from 'moment'
import VerDetalleImagenes from '../../components/administracion/VerDetalleImagenes.vue'
import ModalEditarFormulario from '../../components/administracion/ModalEditarFormulario.vue'

export default {
  data() {
    return {
      panel: 0,

      permisos: {
        ver: false,
        anular: false,
        validar: false,
        asignar: false
      },

      load: false,

      modalNuevoTalonario: false,
      modalEditar: false,
      modalAsignar: false,
      modalAnular: false,
      modalVer: false,

      sucursalesFiltradas: [],

      talonarioSeleccionado: null,

      tipos_talonarios: [],
      formularios: [],
      formulario: {},

      estados: [],
      filtro: {
        familia: null,
        sucursal: null,
        numero: null,
        patente: '',
        cliente: '',
        sucursal: null,
        validoDesde: '',
        validoHasta: '',
        tipo: null,
        estado: null,
        permiso_ver: false
      },

      headers: [
        { text: 'Tipo', value: 'nombre_talonario' },
        { text: 'Número', value: 'Numero' },
        {
          text: 'Fecha',
          value: 'Fecha_crea',
          formatter: this.formatDate
        },
        { text: 'Usuario asignado', value: 'usuario_asignado' },
        { text: 'Patente', value: 'Patente' },
        { text: 'Cliente', value: 'Cliente' },
        { text: 'Estado', value: 'nombre_estado' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async buscar() {
      if (this.filtro.familia) {
        this.filtro.permiso_ver = this.permisos.ver

        this.load = true
        await this.$store
          .dispatch('asignacionFormularios/getFormularios', {
            ...this.filtro
          })
          .then(res => {
            if (res.exito) {
              this.formularios = res.data
            }
          })
        this.load = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar Empresa.',
          color: 'warning'
        })
      }
    },
    async abrirModalAsignar() {
      this.modalAsignar = true
    },

    abrirModalNuevoTalonario() {
      this.modalNuevoTalonario = true
    },

    abrirModalEditar(item) {
      this.formulario = item
      this.modalEditar = true
    },

    abrirModalAnular(item) {
      this.formulario = item
      this.modalAnular = true
    },

    abrirModalVerDetalles(item) {
      this.modalVer = true
      this.formulario = item
    },

    limpiarFiltro() {
      this.filtro = {
        familia: null,
        sucursal: null,
        numero: null,
        nroHasta: null,
        sucursal: null,
        validoDesde: '',
        validoHasta: '',
        tipo: null
      }
    },

    formatDate(date, format) {
      if (!date) return ''
      return moment.utc(date).format(format ? format : 'DD/MM/YYYY')
    },

    async handleActualizar(event) {
      if (event) {
        this.buscar()
      }
    }
  },

  computed: {
    ...mapState('genericos', ['sucursales']),
    ...mapState(['familias']),
  },

  async created() {
    if(this.familias.length == 1) this.filtro.familia = this.familias[0].id
    
    await this.$store.dispatch('talonarios/getTipos').then(res => {
      if (res.exito) {
        this.tipos_talonarios = res.data
        
      }
    })
    await this.$store.dispatch('asignacionFormularios/getEstados').then(res => {
      if (res.exito) {
        this.estados = res.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: res.message,
          color: 'error'
        })
      }
    })
    await this.$store.dispatch('genericos/get_sucursales')
    await this.$store
      .dispatch('asignacionFormularios/getPermisos')
      .then(res => {
        if (res.exito) {
          this.permisos.ver = res.permiso_ver == 1 ? true : false
          this.permisos.anular = res.permiso_anular == 1 ? true : false
          this.permisos.validar = res.permiso_validar == 1 ? true : false
          this.permisos.asignar = res.permiso_asignar == 1 ? true : false

        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
  },

  components: {
    FechaPicker,
    BtnFiltro,
    NuevoTalonario,
    BtnConfirmar,
    EditarTalonario,
    ModalAsignar,
    ModalAnular,
    VerDetalleImagenes,
    ModalEditarFormulario
  }
}
</script>
