<template>
  <v-dialog v-model="dialog" max-width="768" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Editar Talonario</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="3" md="3" class="py-1">
            Empresa
            <v-autocomplete
              v-model="data.familia"
              item-text="nombre"
              item-value="id"
              hide-details
              outlined
              dense
              :items="familias"
              readonly
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Sucursal
            <v-autocomplete
              v-model="data.sucursal"
              item-text="provincia"
              item-value="id"
              hide-details
              outlined
              dense
              readonly
              :items="sucursales.filter(suc => suc.familia_id === data.familia)"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-1">
            Tipo
            <v-autocomplete
              v-model="data.tipo"
              item-text="Nombre"
              item-value="Id"
              hide-details
              outlined
              dense
              readonly
              :items="tipos_talonarios"
              @change="setDesdeHasta"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6" sm="3" xl="2" class="py-1">
            Nº Desde
            <v-text-field
              v-model.number="data.nroDesde"
              hide-details
              outlined
              dense
              type="number"
              @change="validarRango"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" xl="2" class="py-1">
            Nº Hasta
            <v-text-field
              v-model.number="data.nroHasta"
              hide-details
              outlined
              dense
              type="number"
              @change="validarRango"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-2">
            Fecha desde
            <FechaPicker v-model="data.validoDesde" hide-details clearable />
          </v-col>
          <v-col cols="12" sm="3" md="3" class="py-2">
            Fecha hasta
            <FechaPicker v-model="data.validoHasta" hide-details clearable />
          </v-col>
          <v-col cols="6" sm="3" md="3" class="pb-2">
            <v-checkbox
              label="Prefirmado"
              v-model="data.prefirmado"
              class="mt-4"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn
            @click="dialog = false"
            style="margin-left: auto"
            color="primary"
            >Volver</v-btn
          >
          <div class="ml-2">
            <BtnConfirmar
              :disabled="!rangoValido"
              @action="editarTalonario()"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BtnConfirmar from '../util/BtnConfirmar.vue'
import FechaPicker from '../util/FechaPicker.vue'

export default {
  data() {
    return {
      load: false,
      rangoValido: true,
      data: {
        familia: null,
        sucursal: null,
        tipo: null,
        validoDesde: '',
        validoHasta: '',
        nroDesde: null,
        nroHasta: null,
        prefirmado: false
      }
    }
  },

  methods: {
    async editarTalonario() {
      if (this.data.nroDesde <= 0 || this.data.nroHasta <= 0) {
        this.$store.dispatch('show_snackbar', {
          text: 'Los campos Nº Desde y Nº Hasta no pueden ser 0',
          color: 'warning'
        })
        return
      }

      if (this.data.nroDesde && this.data.nroHasta) {
        this.$store.state.loading = true
        await this.$store
          .dispatch('talonarios/editarTalonario', {
            tipo: this.data.tipo.Id,
            talonario_id: this.talonario.Id,
            nroDesde: this.data.nroDesde,
            nroHasta: this.data.nroHasta,
            validoDesde: this.data.validoDesde,
            validoHasta: this.data.validoHasta,
            prefirmado: this.data.prefirmado
          })
          .then(res => {
            if (res.exito) {
              this.$swal.fire({
                icon: 'success',
                title: res.message
              })
              this.$emit('buscar', true)
              this.dialog = false
            } else {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'error'
              })
            }
          })
        this.$store.state.loading = false
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes completar los campos Nº Desde y Nº Hasta',
          color: 'warning'
        })
      }
    },

    setDesdeHasta() {
      this.data.nroDesde = null
      this.data.nroHasta = null
    },

    async validarRango() {
      if (this.dialog) {
        if (this.data.nroHasta < this.data.nroDesde) {
          this.rangoValido = false
          this.$store.dispatch('show_snackbar', {
            text: 'Los intervalos no son correctos.',
            color: 'warning'
          })
          return
        }
        if (this.data.nroDesde && this.data.nroHasta) {
          this.$store.state.loading = true

          await this.$store
            .dispatch('talonarios/validarRangoEditar', {
              tipo: this.data.tipo.Id,
              nroDesde: this.data.nroDesde,
              nroHasta: this.data.nroHasta,
              talonario_id: this.talonario.Id,
              desdeAnterior: this.talonario.Nro_desde,
              hastaAnterior: this.talonario.Nro_hasta
            })
            .then(res => {
              if (!res.exito) {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'warning'
                })
                this.rangoValido = res.exito
              } else {
                this.rangoValido = res.exito
              }
            })
          this.$store.state.loading = false
        }
      }
    }
  },

  watch: {
    dialog(val) {
      if (val) {
        this.data.familia = this.familias.find(
          fam => fam.id === this.talonario.Familia_id
        )?.id
        this.data.sucursal = this.sucursales.find(
          suc => suc.id === this.talonario.Sucursal_id
        )?.id
        this.data.tipo = this.tipos_talonarios.find(
          tip => tip.Id === this.talonario.Tipo_id
        )
        this.data.nroDesde = this.talonario.Nro_desde
        this.data.nroHasta = this.talonario.Nro_hasta
        this.data.validoDesde = this.talonario.Fecha_desde || ''
        this.data.validoHasta = this.talonario.Fecha_hasta || ''
        this.data.prefirmado = this.talonario.Prefirmado
      } else {
        this.data = {
          familia: null,
          sucursal: null,
          tipo: null,
          validoDesde: '',
          validoHasta: '',
          nroDesde: null,
          nroHasta: null
        }
      }
    },
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias']),
    ...mapState('genericos', ['sucursales']),
    ...mapGetters(['una_familia'])
  },

  props: {
    value: Boolean,
    tipos_talonarios: Array,
    talonario: Object
  },

  created() {
    if (this.una_familia) this.data.familia = this.familias[0].id
  },

  components: {
    BtnConfirmar,
    FechaPicker
  }
}
</script>
