<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-btn
              style="position: absolute; top: -15px; right: 105px; z-index: 1"
              color="info"
              title="Descargar números disponibles."
              small
              dark
              fab
              @click="abrirModalDescargar()"
            >
              <v-icon>fas fa-file-excel</v-icon>
            </v-btn>
            <v-btn
              style="position: absolute; top: -15px; right: 55px; z-index: 1"
              color="success"
              small
              fab
              title="Nuevo Talonario"
              @click="abrirModalNuevoTalonario"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
            <v-expansion-panel-header
              class="py-0 px-4 text-h5"
              style="cursor: default"
            >
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>

            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Empresa
                    <v-autocomplete
                      v-model="filtro.familia"
                      item-text="nombre"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="familias"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Sucursal
                    <v-autocomplete
                      v-model="filtro.sucursal"
                      item-text="provincia"
                      item-value="id"
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="
                        sucursales.filter(
                          suc => suc.familia_id === filtro.familia
                        )
                      "
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Tipo
                    <v-autocomplete
                      v-model="filtro.tipo"
                      item-text="Nombre"
                      item-value="Id"
                      hide-details
                      outlined
                      dense
                      :items="tipos_talonarios"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" xl="2" class="py-1">
                    Nº Desde
                    <v-text-field
                      v-model.trim="filtro.nroDesde"
                      hide-details
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" xl="2" class="py-1">
                    Nº Hasta
                    <v-text-field
                      v-model.trim="filtro.nroHasta"
                      hide-details
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="py-1">
                    Estado
                    <v-autocomplete
                      v-model="filtro.estado"
                      item-text="Nombre"
                      item-value="Id"
                      hide-details
                      outlined
                      dense
                      clearable
                      :items="estados"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha desde
                    <FechaPicker
                      v-model="filtro.validoDesde"
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col cols="12" sm="3" md="2" class="py-1">
                    Fecha hasta
                    <FechaPicker
                      v-model="filtro.validoHasta"
                      hide-details
                      clearable
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="12"
                    class="pt-0"
                    style="margin-top: -22px"
                  >
                    <BtnFiltro :loading="load" @clear="limpiarFiltro()" />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-2">
          <v-data-table
            class="cebra elevation-0 mt-2"
            item-key="id"
            sort-by="id"
            :headers="headers"
            :items="talonarios"
            :loading="load"
            sort-desc
            dense
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <BtnConfirmar
                v-if="item.Estado_id == 1"
                clase="mr-1"
                :icon_button="true"
                color="success"
                :small="true"
                icono="fas fa-check-circle"
                @action="validarTalonario(item)"
                title="Validar"
                texto="¿Desea validar este talonario?"
              />
              <v-btn
                v-if="item.Estado_id == 1"
                class=""
                small
                icon
                color="warning"
                @click="abrirModalEditar(item)"
                title="Editar"
              >
                <v-icon class="" small>fas fa-pen</v-icon>
              </v-btn>

              <BtnConfirmar
                v-if="item.Estado_id == 2"
                clase="mr-1"
                :icon_button="true"
                color="info"
                :small="true"
                icono="fas fa-file-download"
                @action="descargarPorTalonario(item)"
                title="Descargar números disponibles"
                texto="¿Desea descargar este talonario?"
              />
              <v-btn
                class=""
                small
                icon
                :color="item.Estado_id == 3 ? 'success' : 'error'"
                @click="
                  item.Estado_id == 3 ? habilitar(item) : inhabilitar(item)
                "
                :title="item.Estado_id == 3 ? 'Habilitar' : 'Inhabilitar'"
              >
                <v-icon class="" small>{{
                  item.Estado_id == 3 ? 'fas fa-check' : 'fas fa-ban'
                }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <NuevoTalonario
      :tipos_talonarios="tipos_talonarios"
      v-model="modalNuevoTalonario"
      @buscar="handleActualizar($event)"
    />
    <EditarTalonario
      :tipos_talonarios="tipos_talonarios"
      v-model="modalEditar"
      :talonario="talonarioSeleccionado"
      @buscar="handleActualizar($event)"
    />
    <DescargarNumerosTalonarios
      v-model="modalDescargar"
      :tipos_talonarios="tipos_talonarios"
    />
    <AnularTalonario
      v-model="modalAnular"
      @buscar="handleActualizar($event)"
      :talonario="talonarioSeleccionado"
      :anular="anular"
    />
  </div>
</template>

<script>
import FechaPicker from '../../components/util/FechaPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'

import { mapState } from 'vuex'
import NuevoTalonario from '../../components/administracion/NuevoTalonario.vue'
import talonarios from '../../modules/administracion/talonarios'
import EditarTalonario from '../../components/administracion/EditarTalonario.vue'
import { utils, write } from 'xlsx'
import FileSaver from 'file-saver'
import DescargarNumerosTalonarios from '../../components/administracion/DescargarNumerosTalonarios.vue'
import AnularTalonario from '../../components/administracion/AnularTalonario.vue'

export default {
  data() {
    return {
      panel: 0,

      load: false,

      modalNuevoTalonario: false,
      modalEditar: false,
      modalDescargar: false,
      modalAnular: false,
      anular: false,

      estados: [],

      sucursalesFiltradas: [],

      talonarioSeleccionado: null,

      tipos_talonarios: [],
      talonarios: [],
      filtro: {
        familia: null,
        sucursal: null,
        nroDesde: null,
        nroHasta: null,
        sucursal: null,
        validoDesde: '',
        validoHasta: '',
        tipo: null,
        estado: null
      },

      headers: [
        { text: 'Tipo', value: 'tipo_nombre' },
        { text: 'Sucursal', value: 'sucursalNombre' },
        { text: 'Estado', value: 'nombre_estado' },
        { text: 'Prefirmado', value: 'nombre_prefirmado' },
        { text: 'Nº Desde', value: 'Nro_desde' },
        { text: 'Nº Hasta', value: 'Nro_hasta' },
        { text: 'Usuario creacion', value: 'Username' },

        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async buscar() {
      // GET talonarios
      if (this.filtro.familia) {
        await this.$store
          .dispatch('talonarios/getTalonarios', {
            ...this.filtro
          })
          .then(res => {
            if (res.exito) {
              this.talonarios = res.data
              this.talonarios.forEach(talonario => {
                talonario.sucursalNombre = this.sucursales.find(
                  suc => suc.id === talonario.Sucursal_id
                )?.provincia
              })
            }
          })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: 'Debes seleccionar Empresa y Sucursal.',
          color: 'warning'
        })
      }
    },

    async inhabilitar(item) {
      this.talonarioSeleccionado = item
      this.modalAnular = true
      this.anular = true
    },

    async habilitar(item) {
      this.talonarioSeleccionado = item
      this.modalAnular = true
      this.anular = false
    },

    handleActualizar(event) {
      if (event) {
        this.buscar()
      }
    },

    abrirModalNuevoTalonario() {
      this.modalNuevoTalonario = true
    },

    abrirModalEditar(item) {
      this.talonarioSeleccionado = item
      this.modalEditar = true
    },

    async validarTalonario(item) {
      await this.$store
        .dispatch('talonarios/validarTalonario', {
          talonario_id: item.Id
        })
        .then(res => {
          if (res.exito) {
            this.$swal.fire({
              icon: 'success',
              title: res.message
            })
            this.talonarios.forEach(talonario => {
              if (talonario.Id === item.Id) {
                talonario.Estado_id = 2
                talonario.nombre_estado = 'Validado'
              }
            })
          } else {
            this.$swal.fire({
              icon: 'error',
              title: res.message
            })
          }
        })
    },

    abrirModalDescargar() {
      this.modalDescargar = true
    },

    async descargarPorTalonario(item) {
      let numerosTalonario = []
      this.$store.state.loading = true
      await this.$store
        .dispatch('talonarios/getNumerosPorTalonario', {
          talonario_id: item.Id
        })
        .then(res => {
          if (res.exito) {
            numerosTalonario = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })
      this.$store.state.loading = false

      const cabecera = [['Número', 'Estado']]

      const ws = utils.aoa_to_sheet(cabecera)
      const array = numerosTalonario

      utils.sheet_add_json(ws, array, {
        header: ['numero', 'estado'],
        skipHeader: true,
        origin: -1
      })

      const columnas = Object.keys(array[0])
      columnas.forEach(columna => {
        const maxLength = array.reduce(
          (max, row) => Math.max(max, String(row[columna]).length),
          0
        )
        ws['!cols'] = ws['!cols'] || []
        ws['!cols'].push({ wch: maxLength + 2 })
      })

      const wb = utils.book_new()
      utils.book_append_sheet(wb, ws, 'Reporte')
      const wbout = write(wb, { bookType: 'xlsx', type: 'array' })
      let name =
        `Talonario_${item.tipo_nombre}_${item.Nro_desde}_${item.Nro_hasta}` +
        '.xlsx'
      FileSaver.saveAs(
        new Blob([wbout], { type: 'application/octet-stream' }),
        name
      )
    },

    limpiarFiltro() {
      this.filtro = {
        familia: null,
        sucursal: null,
        nroDesde: null,
        nroHasta: null,
        sucursal: null,
        validoDesde: '',
        validoHasta: '',
        tipo: null
      }
    }
  },
  computed: {
    ...mapState('genericos', ['sucursales']),
    ...mapState(['familias'])
  },

  async created() {
    if (this.familias.length === 1) this.filtro.familia = this.familias[0].id

    await this.$store.dispatch('talonarios/getEstadosTalonarios').then(res => {
      if (res.exito) {
        this.estados = res.data
      }
    })

    await this.$store.dispatch('talonarios/getTipos').then(res => {
      if (res.exito) {
        this.tipos_talonarios = res.data
      }
    })

    await this.$store.dispatch('genericos/get_sucursales')
  },

  components: {
    FechaPicker,
    BtnFiltro,
    NuevoTalonario,
    BtnConfirmar,
    EditarTalonario,
    DescargarNumerosTalonarios,
    AnularTalonario
  }
}
</script>
